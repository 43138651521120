@charset "UTF-8";
ul.dgs-burger-menu {
  list-style: none;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  ul.dgs-burger-menu, ul.dgs-burger-menu li:not(.last-navigation) > ul:not(.last-navigation) {
    list-style: none;
    background-color: white;
    display: block;
    position: absolute;
    width: 100%;
    padding-top: 2rem; }
  ul.dgs-burger-menu li {
    padding: 0.8rem 0; }
  ul.dgs-burger-menu a.as-text {
    cursor: default; }
  ul.dgs-burger-menu > li > ul {
    z-index: 1;
    top: 0; }
  ul.dgs-burger-menu.text-center li > a {
    width: 90%;
    margin: auto;
    padding: 0.5rem 0.2rem;
    display: block;
    text-align: center; }
  ul.dgs-burger-menu.text-left li {
    text-align: left; }
    ul.dgs-burger-menu.text-left li > a {
      width: 90%;
      margin: 0 0 0 1.5rem;
      padding: 0.5rem 0.2rem;
      display: inline-block; }
  ul.dgs-burger-menu.text-right li {
    text-align: right; }
    ul.dgs-burger-menu.text-right li > a {
      width: 90%;
      margin: 0 1.5rem 0 0;
      padding: 0.5rem 0.2rem;
      display: inline-block; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  /* COG: this is known to cause problems in CQ4 and SharePoint */ }

/* remember to define focus styles! */
:focus {
  outline: 0; }

body {
  line-height: 1;
  background: white;
  position: relative;
  overflow: auto; }

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: ""; }

blockquote, q {
  quotes: "" ""; }

/* COG: make sup and sup working again */
sub {
  vertical-align: sub;
  font-size: 0.9em; }

sup {
  vertical-align: super;
  font-size: 0.9em; }

* {
  box-sizing: border-box; }

select::-ms-expand {
  /* for IE 11 */
  display: none; }

@media only screen and (max-width: 767px) {
  html, body {
    font-size: 15px !important; } }

@media only screen and (min-width: 768px) {
  html, body {
    font-size: 16px !important; } }

@media only screen and (min-width: 1200px) {
  html, body {
    font-size: 16px !important; } }

html, body {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: inherit;
  line-height: 1.6;
  color: #000;
  background-color: #fff; }

h1, .like-h1 {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2.86rem;
  line-height: 1.3;
  color: inherit; }
  @media all and (-ms-high-contrast: none) {
    h1, .like-h1 {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    h1, .like-h1 {
      font-size: 2rem; } }

h2, .like-h2 {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2.14rem;
  line-height: 1.2;
  color: inherit; }
  @media all and (-ms-high-contrast: none) {
    h2, .like-h2 {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    h2, .like-h2 {
      font-size: 1.5rem; } }

h3, .like-h3 {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.43rem;
  line-height: 1.3;
  color: inherit; }
  @media all and (-ms-high-contrast: none) {
    h3, .like-h3 {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    h3, .like-h3 {
      font-size: 1.25rem; } }

h4, .like-h4 {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.14rem;
  line-height: 1.3;
  color: inherit; }
  @media all and (-ms-high-contrast: none) {
    h4, .like-h4 {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    h4, .like-h4 {
      font-size: 1rem; } }

p {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: inherit;
  line-height: 1.6; }

strong {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal; }

pre {
  padding: 2rem;
  background-color: #A9A9A9;
  color: #CC0000; }

h1 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h1 {
      margin-top: 0.5rem; } }

h2 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h1 {
      margin-top: 0.66667rem; } }

h3 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h1 {
      margin-top: 0.66667rem; } }

h4 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h1 {
      margin-top: 0.66667rem; } }

h5 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h1 {
      margin-top: 0.66667rem; } }

p + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h1 {
      margin-top: 0.66667rem; } }

ol + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h1 {
      margin-top: 0.66667rem; } }

ul + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h1 {
      margin-top: 0.66667rem; } }

h1 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h2 {
      margin-top: 0.5rem; } }

h2 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h2 {
      margin-top: 0.66667rem; } }

h3 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h2 {
      margin-top: 0.66667rem; } }

h4 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h2 {
      margin-top: 0.66667rem; } }

h5 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h2 {
      margin-top: 0.66667rem; } }

p + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h2 {
      margin-top: 0.66667rem; } }

ol + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h2 {
      margin-top: 0.66667rem; } }

ul + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h2 {
      margin-top: 0.66667rem; } }

h1 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h3 {
      margin-top: 0.5rem; } }

h2 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h3 {
      margin-top: 0.66667rem; } }

h3 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h3 {
      margin-top: 0.66667rem; } }

h4 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h3 {
      margin-top: 0.66667rem; } }

h5 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h3 {
      margin-top: 0.66667rem; } }

p + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h3 {
      margin-top: 0.66667rem; } }

ol + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h3 {
      margin-top: 0.66667rem; } }

ul + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h3 {
      margin-top: 0.66667rem; } }

h1 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h4 {
      margin-top: 0.5rem; } }

h2 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h4 {
      margin-top: 0.66667rem; } }

h3 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h4 {
      margin-top: 0.66667rem; } }

h4 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h4 {
      margin-top: 0.66667rem; } }

h5 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h4 {
      margin-top: 0.66667rem; } }

p + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h4 {
      margin-top: 0.66667rem; } }

ol + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h4 {
      margin-top: 0.66667rem; } }

ul + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h4 {
      margin-top: 0.66667rem; } }

h1 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h5 {
      margin-top: 0.5rem; } }

h2 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h5 {
      margin-top: 0.66667rem; } }

h3 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h5 {
      margin-top: 0.66667rem; } }

h4 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h5 {
      margin-top: 0.66667rem; } }

h5 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h5 {
      margin-top: 0.66667rem; } }

p + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h5 {
      margin-top: 0.66667rem; } }

ol + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h5 {
      margin-top: 0.66667rem; } }

ul + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h5 {
      margin-top: 0.66667rem; } }

h1 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + p {
      margin-top: 0.5rem; } }

h2 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + p {
      margin-top: 0.66667rem; } }

h3 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + p {
      margin-top: 0.66667rem; } }

h4 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + p {
      margin-top: 0.66667rem; } }

h5 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + p {
      margin-top: 0.66667rem; } }

p + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + p {
      margin-top: 0.66667rem; } }

ol + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + p {
      margin-top: 0.66667rem; } }

ul + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + p {
      margin-top: 0.66667rem; } }

h1 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + ol {
      margin-top: 0.5rem; } }

h2 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + ol {
      margin-top: 0.66667rem; } }

h3 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + ol {
      margin-top: 0.66667rem; } }

h4 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + ol {
      margin-top: 0.66667rem; } }

h5 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + ol {
      margin-top: 0.66667rem; } }

p + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + ol {
      margin-top: 0.66667rem; } }

ol + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + ol {
      margin-top: 0.66667rem; } }

ul + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + ol {
      margin-top: 0.66667rem; } }

h1 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + ul {
      margin-top: 0.5rem; } }

h2 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + ul {
      margin-top: 0.66667rem; } }

h3 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + ul {
      margin-top: 0.66667rem; } }

h4 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + ul {
      margin-top: 0.66667rem; } }

h5 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + ul {
      margin-top: 0.66667rem; } }

p + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + ul {
      margin-top: 0.66667rem; } }

ol + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + ul {
      margin-top: 0.66667rem; } }

ul + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + ul {
      margin-top: 0.66667rem; } }

a, .like-a {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #A9A9A9;
  text-decoration: none; }
  @media all and (-ms-high-contrast: none) {
    a, .like-a {
      font-weight: bold; } }
  a:hover, .like-a:hover {
    color: #D3D3D3; }

.like-a-negative {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #A9A9A9;
  text-decoration: none;
  color: #fff; }
  @media all and (-ms-high-contrast: none) {
    .like-a-negative {
      font-weight: bold; } }
  .like-a-negative:hover {
    color: #D3D3D3; }
  .like-a-negative:hover {
    color: #eee; }

label {
  display: block;
  padding-bottom: 0.5rem; }

input:not([type='radio']):not([type='checkbox']):not([type='submit']),
textarea,
select {
  display: inline-block;
  font-size: 1rem;
  width: auto;
  padding-top: 0.8rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 4px solid #A9A9A9;
  border-radius: 0;
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #000;
  line-height: 1;
  background-color: transparent; }
  input:not([type='radio']):not([type='checkbox']):not([type='submit']):focus,
  textarea:focus,
  select:focus {
    border-color: #333; }

input[type="submit"],
button,
.like-button {
  display: inline-block;
  font-size: 1rem;
  width: auto;
  padding-top: 0.8rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 4px solid #A9A9A9;
  border-radius: 0;
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #A9A9A9;
  line-height: 1;
  padding-left: 2rem;
  padding-right: 2rem;
  text-decoration: none;
  background-color: transparent;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none; }
  input[type="submit"]:not(.disabled),
  button:not(.disabled),
  .like-button:not(.disabled) {
    cursor: pointer; }
  input[type="submit"] a,
  button a,
  .like-button a {
    color: #A9A9A9;
    text-decoration: none;
    background-color: transparent; }
  input[type="submit"]:not(.disabled):hover,
  button:not(.disabled):hover,
  .like-button:not(.disabled):hover {
    color: #fff;
    background-color: #A9A9A9;
    text-shadow: none; }
    input[type="submit"]:not(.disabled):hover a,
    button:not(.disabled):hover a,
    .like-button:not(.disabled):hover a {
      text-decoration: none;
      color: #fff;
      text-shadow: none; }

.like-button-negative {
  display: inline-block;
  font-size: 1rem;
  width: auto;
  padding-top: 0.8rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 4px solid #fff;
  border-radius: 0;
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #fff;
  line-height: 1;
  padding-left: 2rem;
  padding-right: 2rem;
  text-decoration: none;
  background-color: transparent;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none; }
  .like-button-negative:not(.disabled) {
    cursor: pointer; }
  .like-button-negative a {
    color: #fff;
    text-decoration: none;
    background-color: transparent; }
  .like-button-negative:not(.disabled):hover {
    color: #000;
    background-color: #fff;
    text-shadow: none; }
    .like-button-negative:not(.disabled):hover a {
      text-decoration: none;
      color: #000;
      text-shadow: none; }

::selection {
  background: #000;
  color: #D3D3D3; }

[id="content"] .component {
  opacity: 0;
  transition: opacity 1s; }
  [id="content"] .component.hide {
    display: none; }

[id="footer"] {
  opacity: 0;
  transition: opacity 1s; }

[id="content"] .component.component-ready.animate,
[id="footer"].animate {
  opacity: 1; }

[id="content"] .component.component-failed {
  display: none !important; }

[id="content"] .component, [id="footer"] .component {
  padding-top: 2rem;
  padding-bottom: 2rem; }
  @media only screen and (max-width: 767px) {
    [id="content"] .component, [id="footer"] .component {
      padding-top: 1rem; } }
  [id="content"] .component.repeating-background, [id="footer"] .component.repeating-background {
    padding-top: 2rem; }
  @media only screen and (max-width: 767px) {
    [id="content"] .component, [id="footer"] .component {
      padding-bottom: 1rem; } }

ul, ol {
  list-style-position: inside; }
  ul li, ol li {
    line-height: 1.4;
    text-indent: -1rem;
    padding-left: 1rem; }
    ul li + li, ol li + li {
      margin-top: 0.5rem; }

@media only screen and (max-width: 767px) {
  .hide-on-mobile {
    display: none; } }

.clearfix {
  clear: both; }
  .clearfix:before, .clearfix:after {
    content: '';
    display: table; }

.bg-color-1 {
  background-color: #000; }

.text-color-1 {
  color: #000; }

.bg-color-2 {
  background-color: #333; }

.text-color-2 {
  color: #333; }

.bg-color-3 {
  background-color: #A9A9A9; }

.text-color-3 {
  color: #A9A9A9; }

.bg-color-4 {
  background-color: #D3D3D3; }

.text-color-4 {
  color: #D3D3D3; }

.bg-color-5 {
  background-color: #eee; }

.text-color-5 {
  color: #eee; }

.bg-color-6 {
  background-color: #fff; }

.text-color-6 {
  color: #fff; }

.bg-color-7 {
  background-color: #CC0000; }

.text-color-7 {
  color: #CC0000; }

.bg-color-8 {
  background-color: #00CC00; }

.text-color-8 {
  color: #00CC00; }

.negative-elements a, .negative-elements .like-a {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #A9A9A9;
  text-decoration: none;
  color: #fff; }
  @media all and (-ms-high-contrast: none) {
    .negative-elements a, .negative-elements .like-a {
      font-weight: bold; } }
  .negative-elements a:hover, .negative-elements .like-a:hover {
    color: #D3D3D3; }
  .negative-elements a:hover, .negative-elements .like-a:hover {
    color: #eee; }

.negative-elements input[type="submit"],
.negative-elements button,
.negative-elements .like-button {
  display: inline-block;
  font-size: 1rem;
  width: auto;
  padding-top: 0.8rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 4px solid #fff;
  border-radius: 0;
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #fff;
  line-height: 1;
  padding-left: 2rem;
  padding-right: 2rem;
  text-decoration: none;
  background-color: transparent;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none; }
  .negative-elements input[type="submit"]:not(.disabled),
  .negative-elements button:not(.disabled),
  .negative-elements .like-button:not(.disabled) {
    cursor: pointer; }
  .negative-elements input[type="submit"] a,
  .negative-elements button a,
  .negative-elements .like-button a {
    color: #fff;
    text-decoration: none;
    background-color: transparent; }
  .negative-elements input[type="submit"]:not(.disabled):hover,
  .negative-elements button:not(.disabled):hover,
  .negative-elements .like-button:not(.disabled):hover {
    color: #000;
    background-color: #fff;
    text-shadow: none; }
    .negative-elements input[type="submit"]:not(.disabled):hover a,
    .negative-elements button:not(.disabled):hover a,
    .negative-elements .like-button:not(.disabled):hover a {
      text-decoration: none;
      color: #000;
      text-shadow: none; }

.negative-elements input:not([type='radio']):not([type='checkbox']):not([type='submit']),
.negative-elements textarea,
.negative-elements select {
  color: #fff;
  border-color: #333; }

body {
  color: #000; }

html {
  scroll-behavior: smooth; }

:target:before {
  content: "";
  display: block;
  height: 100px;
  margin: -100px 0 0; }

[id="content"] .component {
  padding-top: 2rem;
  padding-bottom: 2rem; }
  @media only screen and (max-width: 767px) {
    [id="content"] .component {
      padding-top: 1rem; } }
  [id="content"] .component.repeating-background {
    padding-top: 2rem; }
  @media only screen and (max-width: 767px) {
    [id="content"] .component {
      padding-bottom: 1rem; } }

.bg-color-1 {
  background-color: #993231;
  color: #fff; }

.bg-color-2 {
  background-color: #dd3333; }

.bg-color-3 {
  background-color: #222; }

.bg-color-4 {
  background-color: #f8f8f8; }

.bg-color-5 {
  background-color: rgba(0, 0, 0, 0.1); }

.bg-color-6 {
  background-color: #fefefe; }

.text-color-1 {
  color: #993231; }

.text-color-2 {
  color: #fff; }

.text-color-3 {
  color: #222; }

.text-color-4 {
  color: #999; }

.component.section-small {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.component.section-default {
  padding-top: 8rem;
  padding-bottom: 8rem; }

.component.section-large {
  padding-top: 12rem;
  padding-bottom: 12rem; }

.component.red-links a {
  color: #993231; }

.component .red-links {
  color: #993231; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 16px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto; }
  .nice-select:hover {
    border-color: #dbdbdb; }
  .nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #999; }
  .nice-select:after {
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 5px; }
  .nice-select.open:after {
    transform: rotate(-135deg); }
  .nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1) translateY(0); }
  .nice-select.disabled {
    border-color: #ededed;
    color: #999;
    pointer-events: none; }
    .nice-select.disabled:after {
      border-color: #cccccc; }
  .nice-select.wide {
    width: 100%; }
    .nice-select.wide .list {
      left: 0 !important;
      right: 0 !important; }
  .nice-select.right {
    float: right; }
    .nice-select.right .list {
      left: auto;
      right: 0; }
  .nice-select.small {
    font-size: 12px;
    height: 36px;
    line-height: 34px; }
    .nice-select.small:after {
      height: 4px;
      width: 4px; }
    .nice-select.small .option {
      line-height: 34px;
      min-height: 34px; }
  .nice-select .list {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-21px);
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9; }
    .nice-select .list:hover .option:not(:hover) {
      background-color: transparent !important; }
  .nice-select .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    transition: all 0.2s; }
    .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
      background-color: #f6f6f6; }
    .nice-select .option.selected {
      font-weight: bold; }
    .nice-select .option.disabled {
      background-color: transparent;
      color: #999;
      cursor: default; }

.no-csspointerevents .nice-select .list {
  display: none; }

.no-csspointerevents .nice-select.open .list {
  display: block; }

.nice-select {
  display: inline-block;
  font-size: 1rem;
  width: auto;
  padding-top: 0.8rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 2px solid #A9A9A9;
  border-radius: 0;
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #000;
  line-height: 1;
  border-radius: 0;
  height: 3.35rem;
  float: none; }
  .nice-select:active, .nice-select.open, .nice-select.open, .nice-select:focus, .nice-select:hover {
    border-color: #A9A9A9; }
  .nice-select > .current {
    display: inline-block;
    line-height: 1.5; }
  .nice-select:after {
    border-bottom: 2px solid #A9A9A9;
    border-right: 2px solid #A9A9A9; }
  .nice-select > .list {
    width: 100%;
    border-radius: 0;
    box-shadow: 0 0 0 2px #A9A9A9;
    margin-top: 2px; }
    .nice-select > .list .option {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.8rem;
      padding-bottom: 1rem;
      line-height: 1;
      text-indent: 0; }
  .resource-center .nice-select {
    min-width: 13.8rem; }

/*
  Generic styling for the mega-menu for it to be display as a list of elements
  Target the dgs-mega-menu, which is build up on nested unordered lists

  IMPORTANT: If you want a specific change for the mega-menu add it on a project file.
 */
#wrapper ul.dgs-mega-menu, #wrapper ul.dgs-mega-menu ul {
  list-style: none; }

#wrapper ul.dgs-mega-menu > li {
  padding: 0 1rem;
  display: inline-block; }
  #wrapper ul.dgs-mega-menu > li > a {
    display: inline-block;
    padding: 1rem 0;
    position: relative;
    text-align: left; }
    #wrapper ul.dgs-mega-menu > li > a.active {
      position: relative; }
    #wrapper ul.dgs-mega-menu > li > a.active:before, #wrapper ul.dgs-mega-menu > li > a.current:before {
      height: 0.3rem;
      background-color: lightgrey;
      visibility: visible;
      content: "";
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      transform: scaleX(1); }
  #wrapper ul.dgs-mega-menu > li > ul {
    display: none;
    position: absolute;
    z-index: 1;
    padding: 2.5rem 0;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0.25rem 0.25rem 0;
    text-align: left; }
    #wrapper ul.dgs-mega-menu > li > ul a {
      display: inline-block;
      padding: 0.5rem 0; }
      #wrapper ul.dgs-mega-menu > li > ul a.as-text {
        cursor: default; }
    #wrapper ul.dgs-mega-menu > li > ul > li {
      padding: 0 3rem;
      overflow-wrap: break-word;
      border-right: 0.05rem solid lightgrey;
      box-sizing: border-box; }
      #wrapper ul.dgs-mega-menu > li > ul > li > a {
        font-weight: 500;
        padding-bottom: 0; }
      #wrapper ul.dgs-mega-menu > li > ul > li ul a {
        font-weight: normal; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.breadcrumb .component-content {
  position: relative;
  width: 95rem;
  max-width: 90%;
  margin: 0 auto; }
  .component.breadcrumb .component-content ol {
    list-style: none; }
    .component.breadcrumb .component-content ol li {
      text-indent: 0;
      padding-left: 0; }
    .component.breadcrumb .component-content ol li, .component.breadcrumb .component-content ol div {
      display: inline-block;
      white-space: nowrap; }
      .component.breadcrumb .component-content ol li:last-child .separator, .component.breadcrumb .component-content ol div:last-child .separator {
        display: none; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.cookie-warning {
  display: none;
  position: fixed;
  top: auto;
  bottom: 0;
  width: 100%;
  z-index: 1030;
  background-color: #333;
  border-top: 1px solid #A9A9A9; }
  .component.cookie-warning .component-content {
    color: #D3D3D3; }
    .component.cookie-warning .component-content .info {
      font-size: .8rem;
      padding: 0.8em 2.5em 0.8em 0.8em; }
    .component.cookie-warning .component-content .submit {
      font-family: "DGS-WebProgram";
      position: absolute;
      top: 0.7em;
      right: 0.7em;
      cursor: pointer;
      font-size: 0.9rem; }
      .component.cookie-warning .component-content .submit::after {
        content: "";
        color: #D3D3D3; }
  .component.cookie-warning.initialized {
    display: block;
    animation: cookieBarBottom 2s; }

@keyframes cookieBarBottom {
  from {
    bottom: -300px; }
  to {
    bottom: 0; } }

.on-page-editor #footer .component.cookie-warning.initialized {
  display: none !important; }

#cookie-policy-rc-spot {
  position: relative;
  width: 95rem;
  max-width: 90%;
  margin: 0 auto; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.form .component-content {
  position: relative;
  width: 77rem;
  max-width: 90%;
  margin: 0 auto; }
  .component.form .component-content .scfTitleBorder {
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2.86rem;
    line-height: 1.3;
    color: inherit;
    margin: 0 0 1rem 0; }
    @media all and (-ms-high-contrast: none) {
      .component.form .component-content .scfTitleBorder {
        font-weight: bold; } }
    @media only screen and (max-width: 767px) {
      .component.form .component-content .scfTitleBorder {
        font-size: 2rem; } }
  .component.form .component-content .scfSectionBorder {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .component.form .component-content .scfDropListBorder,
  .component.form .component-content .scfEmailBorder,
  .component.form .component-content .scfMultipleLineTextBorder,
  .component.form .component-content .scfSingleLineTextBorder,
  .component.form .component-content .scfPasswordBorder,
  .component.form .component-content .scfNumberBorder,
  .component.form .component-content .scfDatePickerBorder,
  .component.form .component-content .scfDateBorder,
  .component.form .component-content .scfRadioButtonListBorder,
  .component.form .component-content .scfListBoxBorder,
  .component.form .component-content .scfCheckBoxListBorder,
  .component.form .component-content .scfFileUploadBorder,
  .component.form .component-content .scfDateSelectorBorder,
  .component.form .component-content .scfCreditCardBorder,
  .component.form .component-content .scfConfirmPasswordBorder,
  .component.form .component-content .scfCaptchaBorder,
  .component.form .component-content .scfTelephoneBorder,
  .component.form .component-content .scfSmsTelephoneBorder {
    margin-bottom: 1.33333rem;
    margin-top: 0;
    position: relative; }
  .component.form .component-content .scfDropListLabel,
  .component.form .component-content .scfEmailLabel,
  .component.form .component-content .scfMultipleLineTextLabel,
  .component.form .component-content .scfSingleLineTextLabel,
  .component.form .component-content .scfPasswordLabel,
  .component.form .component-content .scfNumberLabel,
  .component.form .component-content .scfDatePickerLabel,
  .component.form .component-content .scfDateLabel,
  .component.form .component-content .scfRadioButtonListLabel,
  .component.form .component-content .scfCheckBoxListLabel,
  .component.form .component-content .scfListBoxLabel,
  .component.form .component-content .scfFileUploadLabel,
  .component.form .component-content .scfDateSelectorLabel,
  .component.form .component-content .scfCreditCardLabel,
  .component.form .component-content .scfConfirmPasswordLabel,
  .component.form .component-content .scfCaptchaLabel,
  .component.form .component-content .scfTelephoneLabel,
  .component.form .component-content .scfSmsTelephoneLabel {
    float: none;
    clear: none;
    width: 100%;
    padding: 0 0 0.5rem 0; }
  .component.form .component-content .scfEmailGeneralPanel,
  .component.form .component-content .scfMultipleLineGeneralPanel,
  .component.form .component-content .scfSingleLineGeneralPanel,
  .component.form .component-content .scfPasswordGeneralPanel,
  .component.form .component-content .scfNumberGeneralPanel,
  .component.form .component-content .scfDatePickerGeneralPanel,
  .component.form .component-content .scfDateGeneralPanel,
  .component.form .component-content .scfRadioButtonListGeneralPanel,
  .component.form .component-content .scfCheckBoxListGeneralPanel,
  .component.form .component-content .scfFileUploadGeneralPanel,
  .component.form .component-content .scfDateSelectorGeneralPanel,
  .component.form .component-content .scfCreditCardGeneralPanel,
  .component.form .component-content .scfConfirmPasswordGeneralPanel,
  .component.form .component-content .scfCaptchaGeneralPanel,
  .component.form .component-content .scfTelephoneGeneralPanel,
  .component.form .component-content .scfSmsTelephoneGeneralPanel {
    float: none;
    clear: none;
    width: 100%; }
  .component.form .component-content .scfValidatorRequired,
  .component.form .component-content .scfRequired {
    float: none;
    position: absolute;
    right: 0;
    top: 0.5rem; }
  .component.form .component-content .scfForm input:not([type='checkbox']):not([type='radio']):not([type='submit']),
  .component.form .component-content .scfForm select,
  .component.form .component-content .scfForm textarea {
    width: 100%; }
  .component.form .component-content .scfSubmitButtonBorder {
    text-align: center; }
  .component.form .component-content .scfListBoxGeneralPanel,
  .component.form .component-content .scfDropListGeneralPanel {
    width: 100%;
    float: none; }
  .component.form .component-content .scfError,
  .component.form .component-content .scfValidationSummary,
  .component.form .component-content .scfValidatorRequired,
  .component.form .component-content .scfValidator {
    color: #CC0000; }
  .component.form .component-content .scfValidationSummary {
    margin-left: 0; }
    .component.form .component-content .scfValidationSummary:not(:empty) {
      margin-bottom: 1rem; }
  .component.form .component-content .scfSectionBorderAsFieldSet {
    padding: 0;
    border: 0;
    margin: 0 0 1rem 0; }
  .component.form .component-content .scfSectionLegend {
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.43rem;
    line-height: 1.3;
    color: inherit;
    padding: 0;
    margin: 0;
    margin-bottom: 0.66667rem; }
    @media all and (-ms-high-contrast: none) {
      .component.form .component-content .scfSectionLegend {
        font-weight: bold; } }
    @media only screen and (max-width: 767px) {
      .component.form .component-content .scfSectionLegend {
        font-size: 1.25rem; } }
  .component.form .component-content .scfHiddenFieldBorder {
    display: none; }
  .component.form .component-content input[type=checkbox] + label,
  .component.form .component-content input[type=radio] + label {
    margin-left: .3rem; }

@keyframes form-fade-in {
  0% {
    opacity: 0; }
  80% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.pardot-form .component-content {
  position: relative;
  width: 95rem;
  max-width: 90%;
  margin: 0 auto; }

.pardot-form .element__item__buttonWrapper {
  text-align: center; }
  .pardot-form .element__item__buttonWrapper.button-align-left {
    text-align: left; }

.pardot-form .element__item__button {
  font-weight: 600; }

.pardot-form .element__item__iframe {
  animation: form-fade-in 1.5s;
  transition: max-height 0.5s ease-in-out; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
#wrapper .component.iframe:not(.full-width):not(.slim) .component-content {
  position: relative;
  width: 95rem;
  max-width: 90%;
  margin: 0 auto; }

#wrapper .component.iframe iframe {
  width: 100%; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.image img {
  width: 100%;
  height: auto; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.image-spot .component-content .image-spot-elements .image-spot-element-text, .image-spot .component-content .image-spot-elements .image-spot-element-header {
  max-width: 80%;
  margin: auto; }

.image-spot .component-content {
  position: relative;
  width: 95rem;
  max-width: 90%;
  margin: 0 auto; }
  .image-spot .component-content ul {
    list-style: none; }
  .image-spot .component-content li {
    margin-top: 0;
    line-height: normal; }
  .image-spot .component-content .image-spot-elements {
    text-align: center;
    position: relative; }
    .image-spot .component-content .image-spot-elements .image-spot-element {
      list-style: none;
      margin-bottom: 2rem;
      overflow: hidden;
      text-indent: 0;
      padding-left: 0; }
    .image-spot .component-content .image-spot-elements .image-spot-element-image {
      padding: 0 25%; }
      .image-spot .component-content .image-spot-elements .image-spot-element-image:not(:empty) {
        margin-bottom: 1rem; }
      .image-spot .component-content .image-spot-elements .image-spot-element-image * {
        max-width: 100%;
        height: auto; }
    .image-spot .component-content .image-spot-elements .image-spot-element-link:not(:empty) {
      margin-top: 1rem; }

.image-spot.slim .component-content {
  position: relative;
  width: 77rem;
  max-width: 90%;
  margin: 0 auto; }

.image-spot.round-spot .image-spot-element-image img {
  display: inline-block;
  border-radius: 50%; }

@media only screen and (min-width: 992px) {
  .image-spot .component-content .image-spot-elements {
    /* one item */
    /* two items */
    /* three items */
    /* four items */
    /* five items */
    /* six items */
    /* seven items */
    /* eight items */
    /* nine items */
    /* ten items */
    /* two items */
    /* three items */
    /* four items */
    /* five items */ }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(1) {
      width: 100%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) ~ li {
      width: 50%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) ~ li {
      width: 33.3333%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) ~ li {
      width: 25%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) ~ li {
      width: 20%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(6),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(6) ~ li {
      width: 16.6666%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(7),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(7) ~ li {
      width: 14.2857%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(8),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(8) ~ li {
      width: 12.5%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(9),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(9) ~ li {
      width: 11.1111%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(10),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(10) ~ li {
      width: 10%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) .image-spot-element-image,
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) ~ li .image-spot-element-image {
      padding: 0 4rem; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) .image-spot-element-image,
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) ~ li .image-spot-element-image {
      padding: 0 2rem; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) .image-spot-element-image,
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) ~ li .image-spot-element-image {
      padding: 0 1.5rem; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) .image-spot-element-image,
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) ~ li .image-spot-element-image {
      padding: 0 1rem; }
    .image-spot .component-content .image-spot-elements .image-spot-element {
      margin-bottom: 0; }
  .image-spot .component-content .image-spot-element-context {
    display: none; } }

.component.image-spot .component-content .image-spot-element-text ul {
  list-style-type: disc;
  list-style-position: outside; }
  .component.image-spot .component-content .image-spot-element-text ul li {
    padding-left: 0;
    text-indent: 0; }

.component.image-spot.center-text .image-spot-element .image-spot-element-header {
  text-align: center; }

.component.image-spot.center-text .image-spot-element .image-spot-element-text {
  text-align: center; }

.component.image-spot.center-text .image-spot-element .image-spot-element-link {
  text-align: center; }

.component.image-spot.button-style .image-spot-element .image-spot-element-link a {
  font-size: 1.25rem;
  color: #993231;
  border: 2px solid #993231;
  border-radius: 50px;
  padding: 0.5em 1em;
  display: inline-block;
  transition: all 0.3s;
  font-weight: 400; }
  @media only screen and (min-width: 992px) {
    .component.image-spot.button-style .image-spot-element .image-spot-element-link a {
      font-size: 1rem; } }
  .component.image-spot.button-style .image-spot-element .image-spot-element-link a:after {
    content: none; }
  .component.image-spot.button-style .image-spot-element .image-spot-element-link a:hover {
    background: #eee;
    padding: 0.5em 1.5em;
    border: 2px solid #eee; }
    .component.image-spot.button-style .image-spot-element .image-spot-element-link a:hover:after {
      content: " >"; }

.component.image-spot.remove-button .image-spot-element .image-spot-element-link {
  display: none; }

.component.image-spot.image-left .image-spot-element .image-spot-element-image {
  padding: 0;
  width: 100%;
  float: left; }
  @media only screen and (min-width: 992px) {
    .component.image-spot.image-left .image-spot-element .image-spot-element-image {
      width: 50%;
      padding: 0 2rem 0 0 !important; } }

.component.image-spot .image-spot-element .image-spot-element-image {
  padding: 0; }
  @media only screen and (min-width: 992px) {
    .component.image-spot .image-spot-element .image-spot-element-image {
      padding: inherit; } }

.component.image-spot .image-spot-element .image-spot-element-header {
  text-align: left;
  color: black;
  padding-bottom: 1rem; }

.component.image-spot .image-spot-element .image-spot-element-text {
  text-align: left;
  color: #222; }

.component.image-spot .image-spot-element .image-spot-element-link {
  text-align: left;
  max-width: 78%;
  margin: 0 auto; }
  .component.image-spot .image-spot-element .image-spot-element-link a {
    color: #993231; }
    .component.image-spot .image-spot-element .image-spot-element-link a:after {
      content: " >"; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
#content .component.link-list .component-content {
  position: relative;
  width: 95rem;
  max-width: 90%;
  margin: 0 auto; }

#content .component.link-list.slim .component-content {
  position: relative;
  width: 77rem;
  max-width: 90%;
  margin: 0 auto; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.intro-banner {
  padding-top: 0;
  padding-bottom: 0; }
  .component.intro-banner .component-content {
    position: relative; }
  .component.intro-banner .intro-banner-image {
    width: 100%; }
    .component.intro-banner .intro-banner-image img {
      max-width: 100%;
      height: auto;
      display: block;
      width: 100%; }
  .component.intro-banner .intro-banner-content {
    position: absolute;
    top: 2rem;
    bottom: 2rem;
    left: 0;
    right: 0; }
  .component.intro-banner .outer-pos-wrapper {
    position: relative;
    width: 95rem;
    max-width: 90%;
    margin: 0 auto;
    height: 100%; }
  .component.intro-banner .inner-pos-wrapper {
    width: 100%;
    position: relative;
    display: inline-block; }
    @media only screen and (min-width: 992px) {
      .component.intro-banner .inner-pos-wrapper {
        width: 55%; } }
  .component.intro-banner .intro-banner-link {
    display: none; }
  .component.intro-banner .sticky-downlink {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center; }
    .component.intro-banner .sticky-downlink a {
      display: inline-block;
      transition: all 0.2s;
      color: inherit; }
      .component.intro-banner .sticky-downlink a:before {
        font-family: "DGS-WebProgram";
        content: "";
        font-size: 4rem;
        line-height: 1;
        color: inherit; }
      .component.intro-banner .sticky-downlink a:hover {
        margin-bottom: -.5rem; }
      .component.intro-banner .sticky-downlink a span {
        display: none; }
  .component.intro-banner.middle .inner-pos-wrapper {
    top: 50%;
    transform: translateY(-50%); }
  .component.intro-banner.bottom .inner-pos-wrapper {
    top: 100%;
    transform: translateY(-100%); }
  .component.intro-banner.right .outer-pos-wrapper {
    text-align: right; }
  .component.intro-banner.center .outer-pos-wrapper {
    text-align: center; }
  .component.intro-banner.content-overflow .intro-banner-content {
    position: static;
    padding-top: 2rem;
    padding-bottom: 2rem; }
    @media only screen and (max-width: 767px) {
      .component.intro-banner.content-overflow .intro-banner-content {
        padding-top: 1rem; } }
    @media only screen and (max-width: 767px) {
      .component.intro-banner.content-overflow .intro-banner-content {
        padding-bottom: 1rem; } }
  .component.intro-banner.content-overflow .inner-pos-wrapper {
    position: static;
    transform: none; }

.component.intro-banner.text-right .intro-banner-content {
  text-align: right; }

@media only screen and (min-width: 992px) {
  .component.intro-banner.slim .inner-pos-wrapper {
    width: 40%; } }

.component.intro-banner .intro-banner-link {
  display: inline-flex;
  margin-bottom: 2rem; }

.component.intro-banner.frontpage-banner-gradient {
  background-image: none; }
  @media only screen and (min-width: 992px) {
    .component.intro-banner.frontpage-banner-gradient .inner-pos-wrapper {
      padding: 2rem;
      background: linear-gradient(93deg, rgba(0, 0, 0, 0.26) 0%, rgba(196, 196, 196, 0) 54%);
      text-shadow: 0em 0em 0.3em rgba(0, 0, 0, 0.64); }
      .component.intro-banner.frontpage-banner-gradient .inner-pos-wrapper .intro-banner-text {
        line-height: 1.2; }
        .component.intro-banner.frontpage-banner-gradient .inner-pos-wrapper .intro-banner-text h1 {
          font-weight: 500;
          font-size: 4.2rem; } }

@media only screen and (min-width: 992px) {
  .component.intro-banner.right .inner-pos-wrapper {
    background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.26) 0%, rgba(196, 196, 196, 0) 54%); } }

.component.intro-banner.right .outer-pos-wrapper {
  text-align: left; }
  @media only screen and (min-width: 992px) {
    .component.intro-banner.right .outer-pos-wrapper {
      text-align: right; } }

.component.intro-banner.intro-banner-button a.button-primary {
  background: #666;
  color: #fff;
  border-radius: 100px;
  border-width: 0 !important;
  font-size: 1rem;
  letter-spacing: 0.1em;
  font-weight: 600;
  text-shadow: none;
  text-transform: uppercase !important;
  padding: 1rem 2rem;
  position: relative;
  top: 2rem;
  transition: 0.3s ease; }
  @media only screen and (min-width: 992px) {
    .component.intro-banner.intro-banner-button a.button-primary {
      font-size: 1.2rem;
      font-weight: 600;
      padding: 1.1rem 2.2rem; } }
  .component.intro-banner.intro-banner-button a.button-primary:hover {
    background: #e0c588; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
@media only screen and (min-width: 992px) {
  .content .component[class*="-section-grid"] > .component-content {
    position: relative;
    width: 95rem;
    max-width: 90%;
    margin: 0 auto;
    display: flex; }
    .content .component[class*="-section-grid"] > .component-content > * {
      width: 10%;
      flex-grow: 1;
      flex-basis: 0; }
  .content .component[class*="-section-grid"].wide-thin-columns .section-one {
    width: 66.66%; }
  .content .component[class*="-section-grid"].wide-thin-columns .section-two {
    width: 33.32%; }
  .content .component[class*="-section-grid"].thin-wide-columns .section-one {
    width: 33.32%; }
  .content .component[class*="-section-grid"].thin-wide-columns .section-two {
    width: 66.66%; } }

#wrapper .search-result-spot .component-content {
  position: relative;
  width: 95rem;
  max-width: 90%;
  margin: 0 auto; }

#wrapper .search-result-spot:not([data-no-results="true"]) + .component.no-search-result {
  display: none; }

#wrapper .search-result-spot .search-result ul {
  list-style: none; }
  #wrapper .search-result-spot .search-result ul li {
    text-indent: 0;
    padding-left: 0;
    padding-bottom: 1rem; }
  #wrapper .search-result-spot .search-result ul h3 {
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.14rem;
    line-height: 1.3;
    color: inherit; }
    @media all and (-ms-high-contrast: none) {
      #wrapper .search-result-spot .search-result ul h3 {
        font-weight: bold; } }
    @media only screen and (max-width: 767px) {
      #wrapper .search-result-spot .search-result ul h3 {
        font-size: 1rem; } }
    #wrapper .search-result-spot .search-result ul h3 a {
      color: #000; }

#wrapper .search-result-spot .search-field {
  position: relative;
  width: 77rem;
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 2rem; }
  #wrapper .search-result-spot .search-field input {
    width: 100%; }
  #wrapper .search-result-spot .search-field .search-result-count {
    margin-top: -2.3em;
    z-index: 2;
    line-height: 2em;
    position: absolute;
    right: 3.1em;
    color: #D3D3D3; }
    #wrapper .search-result-spot .search-field .search-result-count span {
      cursor: pointer; }
      #wrapper .search-result-spot .search-field .search-result-count span:after {
        position: absolute;
        margin-left: .5em;
        font-family: "DGS-WebProgram";
        content: "";
        font-size: 1.4em;
        color: #D3D3D3; }

#wrapper .search-field-spot .search-field input,
#wrapper .search-result-spot .search-field input {
  padding: .5rem 1rem;
  border-color: #D3D3D3;
  color: #000;
  background-color: #fff;
  font-size: 1em;
  vertical-align: text-bottom; }

#wrapper .search-field-spot {
  font-size: .8rem; }
  #wrapper .search-field-spot .search-icon {
    display: inline-block; }
    #wrapper .search-field-spot .search-icon:before {
      font-family: "DGS-WebProgram";
      content: "";
      font-size: 1.8em;
      cursor: pointer;
      color: #D3D3D3;
      margin-bottom: 0.1em;
      margin-left: .1em;
      float: left; }

.ui-autocomplete-search {
  list-style: none; }
  .ui-autocomplete-search .ui-menu-item {
    padding: .5rem 1rem;
    background-color: #fff;
    border: 2px solid #D3D3D3;
    border-top: 0;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .8rem;
    margin-right: -4px;
    margin-top: 0;
    text-indent: 0; }
  .ui-autocomplete-search .ui-state-focus {
    background-color: #D3D3D3; }

.ui-helper-hidden-accessible > div {
  display: none; }

#wrapper .search-result-spot .component-content {
  position: relative;
  width: 95rem;
  max-width: 90%;
  margin: 0 auto; }

#wrapper .blur-overlay {
  width: 100%;
  height: 100%;
  opacity: .5;
  position: fixed;
  z-index: 15;
  background-color: #000;
  top: 0; }

#wrapper .search-field-spot .search-field input {
  display: none;
  position: absolute;
  top: 10em;
  left: 0; }
  #wrapper .search-field-spot .search-field input::-ms-clear {
    display: none; }

#wrapper .search-field-spot.search-open .search-field input {
  display: block; }
  #wrapper .search-field-spot.search-open .search-field input::-ms-clear {
    display: none; }

#wrapper .search-field-spot.search-open .search-icon {
  display: block; }

#wrapper .component.search-result-spot:not([data-no-results="true"]) + .component.no-search-result {
  display: none; }

.component.resource-center .dictionary {
  display: none; }

.component.resource-center .component-content .rc2-icon {
  font-family: "DGS-WebProgram"; }

.component.resource-center .spinner {
  margin: 2rem auto;
  width: 15rem;
  text-align: center; }
  .component.resource-center .spinner > div {
    width: 1rem;
    height: 1rem;
    background-color: #333;
    margin: 0 0.3rem;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
  .component.resource-center .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s; }
  .component.resource-center .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.component.resource-center .filters {
  margin-bottom: 1rem; }
  .component.resource-center .filters .main {
    background-color: #f7f6f4;
    padding: 2rem 0; }
    .component.resource-center .filters .main__inner, .component.resource-center .filters .main__free-text-search {
      max-width: 80%;
      width: 95rem;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: center; }
      @media (min-width: 768px) {
        .component.resource-center .filters .main__inner, .component.resource-center .filters .main__free-text-search {
          flex-wrap: nowrap;
          justify-content: flex-start; } }
    .component.resource-center .filters .main__free-text-search input {
      flex-grow: 1; }
      @media (min-width: 768px) {
        .component.resource-center .filters .main__free-text-search input {
          flex-grow: unset;
          width: auto; } }
    .component.resource-center .filters .main__inner + .component.resource-center .filters .main__free-text-search {
      margin-top: 1rem; }
  .component.resource-center .filters .toggle-button {
    flex: 1 0 auto;
    width: 100%; }
    @media (min-width: 768px) {
      .component.resource-center .filters .toggle-button {
        flex: 0 0 auto;
        width: auto;
        margin-right: 1rem; } }
    .component.resource-center .filters .toggle-button__label:before {
      content: "";
      font-family: "DGS-WebProgram";
      padding-right: 0.6rem; }
  .component.resource-center .filters .close-filters-button {
    flex: 1 0 auto;
    width: 100%; }
  .component.resource-center .filters .details {
    background-color: #f0f0f0;
    overflow: hidden;
    -moz-transition: max-height 0.3s;
    -o-transition: max-height 0.3s;
    -webkit-transition: max-height 0.3s;
    transition: max-height 0.3s; }
    @media (min-width: 768px) {
      .component.resource-center .filters .details {
        max-height: 1000px; } }
    .component.resource-center .filters .details--hidden {
      max-height: 0; }
    .component.resource-center .filters .details__inner {
      max-width: 80%;
      width: 95rem;
      margin: auto; }
    .component.resource-center .filters .details__footer {
      padding: 1rem 0; }
      @media (min-width: 768px) {
        .component.resource-center .filters .details__footer {
          display: none; } }
  .component.resource-center .filters .collection-container {
    display: flex;
    flex-direction: column;
    margin: 0 -1rem; }
    @media (min-width: 768px) {
      .component.resource-center .filters .collection-container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1rem 0; } }
  .component.resource-center .filters .summary-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.3rem 0; }
    .component.resource-center .filters .summary-container__filter-summary {
      margin-right: 0.5rem; }
      @media (min-width: 768px) {
        .component.resource-center .filters .summary-container__filter-summary {
          display: none; } }
    .component.resource-center .filters .summary-container__chip {
      display: none;
      margin: 0.3rem; }
      @media (min-width: 768px) {
        .component.resource-center .filters .summary-container__chip {
          display: inline-flex; } }
  .component.resource-center .filters .clear-all-button {
    display: inline-flex;
    border: none;
    background: none;
    color: #4092c5;
    margin: 0.3rem;
    padding: 0.3rem; }
    @media (min-width: 768px) {
      .component.resource-center .filters .clear-all-button {
        font-size: 0.8rem; } }
    .component.resource-center .filters .clear-all-button:hover {
      color: #3176a1; }

.component.resource-center .chip {
  display: flex;
  align-items: center;
  padding: 0.3rem 1.2rem 0.3rem 0.3rem;
  color: white;
  font-size: 0.8rem;
  background-color: #6b6867;
  border-radius: 999px; }
  .component.resource-center .chip__close-button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
    overflow: hidden;
    background-color: #fff;
    border-radius: 999px; }
    .component.resource-center .chip__close-button::before, .component.resource-center .chip__close-button::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 50%;
      top: 50%;
      left: 25%;
      margin-top: -1px;
      background-color: #6b6867; }
    .component.resource-center .chip__close-button::before {
      transform: rotate(45deg); }
    .component.resource-center .chip__close-button::after {
      transform: rotate(-45deg); }

.component.resource-center .filters .collection {
  padding: 0 1rem; }
  @media (min-width: 768px) {
    .component.resource-center .filters .collection {
      margin-bottom: 1rem;
      flex: 1 1 0; } }
  .component.resource-center .filters .collection--collapsed .collection__list-wrapper {
    max-height: 0;
    border-bottom: 0; }
    @media (min-width: 768px) {
      .component.resource-center .filters .collection--collapsed .collection__list-wrapper {
        max-height: 200px; } }
  .component.resource-center .filters .collection--collapsed .collection__header:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .component.resource-center .filters .collection__header {
    position: relative;
    padding: 1.5rem 0;
    border-bottom: 2px solid #ccc;
    cursor: pointer; }
    @media (min-width: 768px) {
      .component.resource-center .filters .collection__header {
        margin-bottom: 1rem;
        padding: 1rem 0;
        cursor: auto;
        pointer-events: none; } }
    .component.resource-center .filters .collection__header:after {
      -moz-transition: transform 0.2s;
      -o-transition: transform 0.2s;
      -webkit-transition: transform 0.2s;
      transition: transform 0.2s;
      -webkit-transform-origin: 25% 75%;
      transform-origin: 25% 75%;
      position: absolute;
      display: block;
      content: "";
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
      top: calc(50% - 0.75rem / 2);
      right: 0;
      width: 0.75rem;
      height: 0.75rem;
      border-left: 2px solid #4092c5;
      border-bottom: 2px solid #4092c5;
      pointer-events: none; }
      @media (min-width: 768px) {
        .component.resource-center .filters .collection__header:after {
          display: none; } }
    .component.resource-center .filters .collection__header__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 2rem;
      font-size: 1.5rem; }
      @media (min-width: 768px) {
        .component.resource-center .filters .collection__header__title {
          font-size: 1rem;
          padding-right: 0; } }
  .component.resource-center .filters .collection__list-wrapper {
    max-height: 999px;
    overflow: auto;
    border-bottom: 2px solid #ccc;
    box-sizing: border-box;
    transition: height 1s;
    -moz-transition: max-height 0.3s;
    -o-transition: max-height 0.3s;
    -webkit-transition: max-height 0.3s;
    transition: max-height 0.3s; }
    @media (min-width: 768px) {
      .component.resource-center .filters .collection__list-wrapper {
        border-bottom: none;
        max-height: none; } }
    .component.resource-center .filters .collection__list-wrapper::-webkit-scrollbar {
      width: 5px; }
    .component.resource-center .filters .collection__list-wrapper::-webkit-scrollbar-track {
      background: #ddd; }
    .component.resource-center .filters .collection__list-wrapper::-webkit-scrollbar-thumb {
      background: #666; }
  .component.resource-center .filters .collection__list {
    list-style: none;
    padding: 0 1rem 0 0;
    margin: 1rem 0 0 0; }
    .component.resource-center .filters .collection__list li {
      text-indent: 0;
      padding-left: 0; }

.component.resource-center .filters .collection-element__wrapper {
  display: block;
  position: relative;
  padding: 0 0 1rem 32px;
  line-height: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .component.resource-center .filters .collection-element__wrapper:hover input ~ .collection-element__checkmark {
    background-color: #ebebeb; }
  .component.resource-center .filters .collection-element__wrapper input:checked ~ .collection-element__checkmark {
    background-color: white; }
  .component.resource-center .filters .collection-element__wrapper input:checked ~ .collection-element__checkmark:after {
    display: block; }
  .component.resource-center .filters .collection-element__wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }

.component.resource-center .filters .collection-element__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: white;
  border: 1px solid #d0cdc9;
  margin: 0; }
  .component.resource-center .filters .collection-element__checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: 2px solid #4092c5;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

@media (min-width: 768px) {
  .component.resource-center .resources .content-type-container > .inner-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

.component.resource-center .resources .content-type {
  display: inline-block;
  width: 100%;
  padding: 0 0 2rem 0; }
  @media (min-width: 768px) {
    .component.resource-center .resources .content-type {
      width: 45%;
      padding: 0 0 2rem 0; } }
  .component.resource-center .resources .content-type__header {
    position: relative;
    margin: 0;
    color: black;
    pointer-events: none;
    cursor: auto; }
    .component.resource-center .resources .content-type__header > h4 {
      font-size: 1.14rem; }
      @media (min-width: 768px) {
        .component.resource-center .resources .content-type__header > h4 {
          font-size: 1.14rem; } }
    .component.resource-center .resources .content-type__header .content-type-count {
      padding-left: 1rem;
      color: white;
      font-weight: bold; }
      .component.resource-center .resources .content-type__header .content-type-count::before {
        content: "[ "; }
      .component.resource-center .resources .content-type__header .content-type-count::after {
        content: " ]"; }
      @media (min-width: 768px) {
        .component.resource-center .resources .content-type__header .content-type-count {
          color: grey; }
          .component.resource-center .resources .content-type__header .content-type-count::before, .component.resource-center .resources .content-type__header .content-type-count::after {
            content: ""; } }
  .component.resource-center .resources .content-type .expand-button {
    display: block;
    color: #4092c5;
    margin-top: 1rem;
    font-size: 0.8rem;
    border: 0;
    padding: 0; }
    .component.resource-center .resources .content-type .expand-button:hover {
      color: #3176a1;
      background: none; }

.component.resource-center .resources .content-type-hide {
  display: none; }

.component.resource-center .file-link {
  display: inline-block;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  color: grey; }
  .component.resource-center .file-link:nth-of-type(even) {
    background-color: white; }
  .component.resource-center .file-link:nth-of-type(odd) {
    background-color: lightgrey; }
  @media (min-width: 768px) {
    .component.resource-center .file-link {
      font-size: 1rem; } }
  .component.resource-center .file-link span {
    margin-bottom: 0; }
  .component.resource-center .file-link:focus {
    background-color: #b3b3b3; }
  .component.resource-center .file-link:hover {
    background-color: #ccc; }
  .component.resource-center .file-link.file-locked::after {
    font-family: "DGS-WebProgram";
    margin-left: 0.5rem;
    content: ""; }

.component.resource-center .content-type-icon {
  display: inline-block;
  font-family: "DGS-WebProgram";
  margin-right: 0.5rem; }

.component.resource-center.grid-layout {
  margin-left: 0; }
  .component.resource-center.grid-layout .resources .content-type {
    width: 100%;
    padding: 0 0 2rem 0; }
  .component.resource-center.grid-layout .resources .content-type-files {
    display: flex;
    margin-right: -1.66%;
    margin-bottom: -1.66%;
    flex-wrap: wrap; }
  .component.resource-center.grid-layout .resources .content-type-hide {
    display: none; }
  .component.resource-center.grid-layout .resources .content-type-container > .inner-container {
    display: block;
    justify-content: space-between; }
  .component.resource-center.grid-layout .resources .grid-view__header {
    margin-bottom: 0.5rem; }

.component.resource-center.grid-layout .file-link {
  display: block;
  flex-grow: 0;
  box-sizing: border-box;
  text-align: center;
  background-color: #e2e2e2;
  width: calc( 100% / 2 - 1.66%);
  margin-right: 1.66%;
  margin-bottom: 1.66%;
  cursor: pointer; }
  @media (min-width: 768px) {
    .component.resource-center.grid-layout .file-link {
      width: calc( 100% / 3 - 1.66%); } }
  @media (min-width: 992px) {
    .component.resource-center.grid-layout .file-link {
      width: calc( 100% / 4 - 1.66%); } }
  .component.resource-center.grid-layout .file-link:hover {
    background-color: #d5d5d5; }
  .component.resource-center.grid-layout .file-link:focus {
    background-color: #c9c9c9; }
    .component.resource-center.grid-layout .file-link:focus .title {
      font-weight: 700; }
  .component.resource-center.grid-layout .file-link .image {
    display: inline-block;
    width: 100%;
    padding-top: 100%;
    background-size: cover;
    background-repeat: none;
    background-position: center; }
  .component.resource-center.grid-layout .file-link .title {
    max-width: 90%;
    display: inline-block; }

.component.resource-center .detail-lightbox {
  position: fixed;
  top: 10rem;
  right: 0;
  left: 0;
  padding: 1rem 0;
  max-width: 80%;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .component.resource-center .detail-lightbox {
      top: 0;
      bottom: 0;
      max-width: none;
      z-index: 9999;
      background-color: rgba(128, 128, 128, 0.3); } }
  .component.resource-center .detail-lightbox .inner-container {
    position: relative;
    padding: 2rem;
    background-color: white;
    border: 1px solid grey;
    overflow: auto; }
    @media (min-width: 768px) {
      .component.resource-center .detail-lightbox .inner-container {
        position: relative;
        width: 40rem;
        max-height: 75%;
        top: 50%;
        margin: 0 auto;
        transform: translatey(-50%);
        background-color: lightgrey;
        box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2); } }
    .component.resource-center .detail-lightbox .inner-container .lightbox-close {
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 25px;
      height: 25px;
      padding: 0;
      border: 0;
      background-color: #000; }
      .component.resource-center .detail-lightbox .inner-container .lightbox-close:hover {
        opacity: 1;
        background-color: #000; }
        .component.resource-center .detail-lightbox .inner-container .lightbox-close:hover:before, .component.resource-center .detail-lightbox .inner-container .lightbox-close:hover:after {
          background-color: #666; }
      .component.resource-center .detail-lightbox .inner-container .lightbox-close:before, .component.resource-center .detail-lightbox .inner-container .lightbox-close:after {
        content: " ";
        position: absolute;
        left: 5px;
        top: 12px;
        width: 15px;
        height: 1px;
        background-color: #fff; }
      .component.resource-center .detail-lightbox .inner-container .lightbox-close:before {
        transform: rotate(45deg); }
      .component.resource-center .detail-lightbox .inner-container .lightbox-close:after {
        transform: rotate(-45deg); }
    .component.resource-center .detail-lightbox .inner-container .lightbox-fileimg {
      padding-top: 70%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: 2rem;
      user-select: none; }
    .component.resource-center .detail-lightbox .inner-container .lightbox-filename {
      text-align: center; }
    .component.resource-center .detail-lightbox .inner-container .lightbox-download {
      display: block;
      width: 16rem;
      margin: 1.5rem auto 0;
      padding: 1rem;
      text-align: center;
      color: white;
      background-color: black;
      user-select: none; }
      .component.resource-center .detail-lightbox .inner-container .lightbox-download:hover {
        color: #cccccc;
        background-color: #4d4d4d; }
      .component.resource-center .detail-lightbox .inner-container .lightbox-download:focus {
        color: #e6e6e6;
        background-color: #333333; }

.component.resource-center .component-content .resources {
  position: relative;
  width: 95rem;
  max-width: 90%;
  margin: 0 auto; }

.component.resource-center .component-content .filters .toggle-button {
  border: 1px solid #993231;
  color: #993231;
  border: 2px solid #993231;
  border-radius: 50px;
  padding: 0.5em 1em;
  display: inline-block;
  transition: all 0.3s;
  font-weight: 400; }
  .component.resource-center .component-content .filters .toggle-button:after {
    content: none; }
  .component.resource-center .component-content .filters .toggle-button:hover {
    background: #eee;
    padding: 0.5em 1.5em;
    border: 2px solid #eee; }
    .component.resource-center .component-content .filters .toggle-button:hover:after {
      content: " >"; }

.component.resource-center .component-content .collection-element__checkmark::after {
  border-right: 2px solid #993231;
  border-bottom: 2px solid #993231; }

.component.resource-center .component-content .clear-all-button {
  color: #993231; }

.component.resource-center .component-content .resources .content-type__header {
  margin-bottom: 1rem; }

.component.resource-center .component-content .resources .content-type-files a {
  background-color: #fff;
  border: 1px solid #993231;
  color: #222; }
  .component.resource-center .component-content .resources .content-type-files a:hover {
    background-color: #eee; }

.component.resource-center .component-content .resources .expand-button {
  color: #993231; }
  .component.resource-center .component-content .resources .expand-button:hover {
    color: #dd3333; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
#wrapper .blur-overlay {
  opacity: 0.8; }

#wrapper .component.search-field-spot {
  margin: 1rem 1rem 0 0; }

#wrapper .component.search-field-spot .search-field input[type="text"], #wrapper .component.search-result-spot .search-field input[type="text"] {
  border: 1px solid #993231;
  border-radius: 4px;
  width: 100%;
  background-color: #fff;
  padding: 14px 0 14px 14px !important; }

#wrapper .component.search-field-spot .search-result, #wrapper .component.search-result-spot .search-result {
  padding-top: 3em;
  margin-top: 0 !important;
  text-align: center; }
  #wrapper .component.search-field-spot .search-result h3 > a, #wrapper .component.search-field-spot .search-result .search-summary, #wrapper .component.search-result-spot .search-result h3 > a, #wrapper .component.search-result-spot .search-result .search-summary {
    color: black;
    display: block;
    padding-bottom: 0.1em; }
  #wrapper .component.search-field-spot .search-result ul > li, #wrapper .component.search-result-spot .search-result ul > li {
    padding-bottom: 1.3em; }
    #wrapper .component.search-field-spot .search-result ul > li > a, #wrapper .component.search-result-spot .search-result ul > li > a {
      display: block;
      padding-bottom: 0.3em;
      word-wrap: break-word; }
    #wrapper .component.search-field-spot .search-result ul > li h3, #wrapper .component.search-result-spot .search-result ul > li h3 {
      padding: 0; }

#wrapper .component.search-result-spot .search-field {
  margin-top: 2rem;
  margin-bottom: 0; }

#wrapper .component.search-result-spot .search-result-count {
  color: #2ea3f2;
  margin-top: 0;
  z-index: 2;
  line-height: 2em;
  position: absolute;
  right: 3.5em;
  bottom: 0.8em; }
  #wrapper .component.search-result-spot .search-result-count span:after {
    cursor: pointer;
    transition: transform 0.2s;
    position: absolute;
    margin-left: 0.7em;
    font-size: 1.4em;
    font-family: "DGS-WebProgram";
    content: ""; }

#wrapper .component.search-field-spot {
  float: right;
  display: inline-block;
  cursor: pointer; }
  #wrapper .component.search-field-spot .search-icon {
    display: none; }
  #wrapper .component.search-field-spot::before {
    color: #993231;
    font-size: 1.5rem;
    position: relative;
    font-weight: 600;
    opacity: 1;
    font-family: "DGS-WebProgram";
    content: ""; }
  #wrapper .component.search-field-spot.search-open .search-field * {
    z-index: 0; }
  #wrapper .component.search-field-spot.search-open:before {
    content: ""; }

#wrapper .component.no-search-result.rich-text .component-content {
  max-width: 90%;
  margin: 0 auto;
  width: 50em; }

#wrapper .mobile-section .component.search-field-spot {
  padding: 0 10%; }
  #wrapper .mobile-section .component.search-field-spot.search-open {
    width: 80%;
    padding: 0 10% 5%; }
  #wrapper .mobile-section .component.search-field-spot::before {
    position: absolute;
    right: 4.2rem;
    top: 1.2rem;
    color: #993231; }
  #wrapper .mobile-section .component.search-field-spot input[type="text"] {
    position: absolute;
    width: 90%;
    max-width: none;
    margin-left: 5%;
    margin-top: 10%; }

.ui-autocomplete {
  padding: 0;
  margin: 0;
  border: 1px solid black;
  border-top: none;
  background: none;
  z-index: 16;
  margin-left: 1.5em; }
  .ui-autocomplete .ui-menu-item {
    background: #fff;
    font-size: 1em;
    padding: 1em 1.2em 0.9em 1.2em;
    margin-right: 0; }
    .ui-autocomplete .ui-menu-item.ui-state-focus {
      background-color: #fff;
      border: none;
      color: black; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.rich-text-list .component-content {
  position: relative;
  width: 95rem;
  max-width: 90%;
  margin: 0 auto; }
  .component.rich-text-list .component-content:before, .component.rich-text-list .component-content:after {
    content: '';
    clear: both;
    display: table; }
  .component.rich-text-list .component-content > ul {
    list-style: none; }
    .component.rich-text-list .component-content > ul > li {
      width: 100%;
      text-indent: 0;
      padding-left: 0; }
      .component.rich-text-list .component-content > ul > li + li {
        margin-top: 2rem; }
      .component.rich-text-list .component-content > ul > li ul {
        list-style: none; }
        .component.rich-text-list .component-content > ul > li ul li + li {
          margin-top: .7rem; }
        .component.rich-text-list .component-content > ul > li ul li:not(:last-child) {
          padding-bottom: .7rem;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
      .component.rich-text-list .component-content > ul > li:not(:last-child) {
        padding-right: 2rem; }

@media only screen and (min-width: 992px) {
  .component.rich-text-list .component-content > ul {
    /* one item */
    /* two items */
    /* three items */
    /* four items */
    /* five items */
    /* six items */
    /* seven items */
    /* eight items */
    /* nine items */
    /* ten items */ }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(1) {
      width: 100%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(2),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(2) ~ li {
      width: 50%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(3),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(3) ~ li {
      width: 33.3333%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li {
      width: 25%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(5),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(5) ~ li {
      width: 20%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(6),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(6) ~ li {
      width: 16.6666%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(7),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(7) ~ li {
      width: 14.2857%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(8),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(8) ~ li {
      width: 12.5%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(9),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(9) ~ li {
      width: 11.1111%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(10),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(10) ~ li {
      width: 10%;
      float: left; }
    .component.rich-text-list .component-content > ul > li + li {
      margin-top: 0; } }

.component.rich-text-list .component-content {
  position: relative;
  width: 95rem;
  max-width: 90%;
  margin: 0 auto; }
  .component.rich-text-list .component-content > ul > li:not(:last-child) {
    padding-right: 0rem; }
    @media only screen and (min-width: 992px) {
      .component.rich-text-list .component-content > ul > li:not(:last-child) {
        padding-right: 2rem; } }

.rich-text-list ul li ul {
  list-style: inside disc !important; }
  .rich-text-list ul li ul li {
    padding-left: 30px;
    text-indent: -23px; }

#footer .rich-text-list ul li ul li {
  list-style-type: none;
  text-indent: 1rem;
  padding-left: 1rem; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.rich-text .component-content {
  position: relative;
  width: 95rem;
  max-width: 90%;
  margin: 0 auto; }

.component.rich-text.slim .component-content {
  position: relative;
  width: 77rem;
  max-width: 90%;
  margin: 0 auto; }

.component.rich-text.overlay {
  padding: 0 !important;
  opacity: 0 !important;
  position: relative;
  transition: opacity 0.1s ease-in; }
  .component.rich-text.overlay .component-content {
    transition: all 0.2s ease-in;
    position: absolute;
    top: 0;
    left: -25%;
    right: 100%;
    transform: translateY(-100%);
    padding: 1rem;
    background-color: black;
    color: #fff;
    margin: 0;
    width: auto;
    max-width: none; }
    .component.rich-text.overlay .component-content * {
      color: white; }
    .component.rich-text.overlay .component-content h1, .component.rich-text.overlay .component-content h2, .component.rich-text.overlay .component-content h3, .component.rich-text.overlay .component-content h4 {
      font-size: 1rem; }
    .component.rich-text.overlay .component-content p {
      font-size: 0.8rem; }
  .component.rich-text.overlay.active {
    opacity: 1 !important; }
    .component.rich-text.overlay.active .component-content {
      left: 0;
      right: 75%; }

.on-page-editor #wrapper > #content .component.rich-text {
  min-height: 100px; }

.rich-text.top-product-menu ul {
  text-align: right;
  display: table-row;
  right: 0;
  position: absolute; }
  .rich-text.top-product-menu ul li {
    margin-top: 0;
    display: table-cell;
    list-style: none;
    text-indent: 0 !important;
    padding-left: 2rem !important; }
    .rich-text.top-product-menu ul li a {
      color: #993231;
      font-weight: 400;
      transition: 0.2s ease; }
      .rich-text.top-product-menu ul li a:hover {
        color: #dd3333; }

.rich-text.enlarge {
  padding: 5rem 0;
  font-size: 1rem; }
  .rich-text.enlarge h1, .rich-text.enlarge h2, .rich-text.enlarge h3, .rich-text.enlarge h4 {
    font-size: 1.5rem;
    font-weight: 400; }

.rich-text.center {
  text-align: center; }

.rich-text.link-button.bg-color-1 a.button-primary {
  padding: 0.6rem 1.2rem;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 50px;
  margin-top: 2rem;
  display: inline-block;
  font-weight: 400;
  font-size: 1.5rem;
  transition: all 0.3s; }
  .rich-text.link-button.bg-color-1 a.button-primary:hover {
    background: rgba(255, 255, 255, 0.2);
    border: 2px solid transparent;
    padding: 0.6rem 1.4rem; }

.rich-text.link-button a.button-primary {
  padding: 0.6rem 1.2rem;
  border: 2px solid #993231;
  color: #993231;
  border-radius: 50px;
  margin-top: 2rem;
  display: inline-block;
  font-weight: 400;
  font-size: 1.5rem;
  transition: all 0.3s; }
  .rich-text.link-button a.button-primary:hover {
    background: rgba(128, 128, 128, 0.2);
    border: 2px solid transparent;
    padding: 0.6rem 1.4rem; }

.rich-text.large-text {
  font-size: 1.5rem; }

.rich-text.subnav {
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .rich-text.subnav {
      display: none; } }
  .rich-text.subnav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    float: left; }
    @media only screen and (min-width: 992px) {
      .rich-text.subnav ul {
        float: right; } }
  .rich-text.subnav li {
    float: left;
    margin-top: 0; }
  .rich-text.subnav li a {
    display: block;
    color: #dd3333;
    text-align: center;
    padding: 16px;
    text-decoration: none;
    font-style: italic; }
  .rich-text.subnav li a:hover {
    color: #993231; }

.rich-text ul li, .rich-text ol li {
  text-indent: -1.4rem;
  padding-left: 1.4rem; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.text-image-spot.iwide .text-image-spot-image-container {
  width: 100%; }
  @media only screen and (min-width: 992px) {
    .component.text-image-spot.iwide .text-image-spot-image-container {
      width: 70%; } }

.component.text-image-spot.iwide .text-image-spot-text-container {
  width: 100%; }
  @media only screen and (min-width: 992px) {
    .component.text-image-spot.iwide .text-image-spot-text-container {
      width: 30%; } }

.component.text-image-spot.islim .text-image-spot-image-container {
  width: 100%; }
  @media only screen and (min-width: 992px) {
    .component.text-image-spot.islim .text-image-spot-image-container {
      width: 33%; } }

.component.text-image-spot.islim .text-image-spot-text-container {
  width: 100%; }
  @media only screen and (min-width: 992px) {
    .component.text-image-spot.islim .text-image-spot-text-container {
      width: 67%; } }

.component.text-image-spot.ileft .text-image-spot-image-container {
  float: left;
  padding-right: 3rem;
  width: 100%; }
  @media only screen and (min-width: 992px) {
    .component.text-image-spot.ileft .text-image-spot-image-container {
      width: 50%; } }

.component.text-image-spot.ileft .text-image-spot-text-container {
  float: right;
  width: 100%; }
  @media only screen and (min-width: 992px) {
    .component.text-image-spot.ileft .text-image-spot-text-container {
      width: 50%; } }

.component.text-image-spot.iright .text-image-spot-image-container {
  float: right;
  padding-left: 3rem;
  width: 100%; }
  @media only screen and (min-width: 992px) {
    .component.text-image-spot.iright .text-image-spot-image-container {
      width: 50%; } }

.component.text-image-spot.iright .text-image-spot-text-container {
  float: left;
  width: 100%;
  width: 100%; }
  @media only screen and (min-width: 992px) {
    .component.text-image-spot.iright .text-image-spot-text-container {
      width: 50%; } }

.component.text-image-spot .text-image-spot-image-container {
  float: left;
  width: 100%; }
  .component.text-image-spot .text-image-spot-image-container img {
    width: 100%;
    height: auto; }
  @media only screen and (min-width: 992px) {
    .component.text-image-spot .text-image-spot-image-container {
      width: 50%;
      padding-right: 3rem; } }

.component.text-image-spot .text-image-spot-text-container {
  float: right;
  width: 100%; }
  @media only screen and (min-width: 992px) {
    .component.text-image-spot .text-image-spot-text-container {
      width: 50%;
      padding-top: 12%; } }

.component.text-image-spot .component-content {
  width: 95rem;
  max-width: 90%;
  margin: 0 auto;
  position: relative; }

.component.text-image-spot.feature h1, .component.text-image-spot.feature h2, .component.text-image-spot.feature h3, .component.text-image-spot.feature h4 {
  font-size: 3.2rem;
  font-weight: 400;
  padding-bottom: 3rem; }

.component.text-image-spot.feature p {
  color: #222;
  font-size: 1.2rem;
  padding-bottom: 2rem; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.video-spot .component-content {
  position: relative;
  width: 95rem;
  max-width: 90%;
  margin: 0 auto; }
  .component.video-spot .component-content code {
    display: none; }
  .component.video-spot .component-content .video-spot-container {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    cursor: pointer; }
  .component.video-spot .component-content .video-spot-container,
  .component.video-spot .component-content .video-spot-video,
  .component.video-spot .component-content .video-spot-image,
  .component.video-spot .component-content img {
    display: inline-block; }
  .component.video-spot .component-content .video-spot-play-btn {
    display: none; }
  .component.video-spot .component-content .video-spot-image img {
    max-width: 100%;
    height: auto; }

.component.video-spot.slim .component-content {
  position: relative;
  width: 77rem;
  max-width: 90%;
  margin: 0 auto; }

.video-spot-overlay {
  display: none;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999; }
  .video-spot-overlay .video-spot-overlay-close {
    width: 3rem;
    height: 3rem;
    position: fixed;
    top: 3rem;
    right: 3rem;
    cursor: pointer; }
    .video-spot-overlay .video-spot-overlay-close::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      font-family: "DGS-WebProgram";
      speak: none;
      font-size: 3rem;
      color: #000; }
  .video-spot-overlay .video-spot-overlay-video {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
#header {
  opacity: 0; }
  #header.animate {
    opacity: 1; }
  #header .desktop-section {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; }
    #header .desktop-section > .inner-container {
      background-color: #eee; }
    #header .desktop-section::after {
      content: "";
      display: block;
      position: relative;
      width: 100%;
      height: 1.1rem;
      background-color: transparent;
      top: -1.2rem;
      box-shadow: 0 0.3rem 0.4rem -0.4rem rgba(0, 0, 0, 0.2); }
    #header .desktop-section .header-desktop-top > .inner-container {
      position: relative;
      width: 95rem;
      max-width: 90%;
      margin: 0 auto; }
      #header .desktop-section .header-desktop-top > .inner-container .row-1 .component.image img {
        float: left;
        width: 13rem;
        margin: 0.9rem 0 0 0; }
      #header .desktop-section .header-desktop-top > .inner-container .row-1 .component.search-field-spot {
        float: right;
        margin: 1rem; }
      #header .desktop-section .header-desktop-top > .inner-container .row-1 .component.navigation {
        float: right;
        margin: 0.6rem 0 0 0; }
      #header .desktop-section .header-desktop-top > .inner-container .row-1::after {
        content: '';
        display: block;
        clear: both; }
  #header .mobile-section .header-mobile-top {
    position: fixed;
    z-index: 100;
    background-color: #D3D3D3;
    width: 100%; }
    #header .mobile-section .header-mobile-top .inner-container::after {
      content: '';
      display: block;
      clear: both; }
    #header .mobile-section .header-mobile-top .image {
      width: 50%;
      padding: 1rem;
      float: left; }
    #header .mobile-section .header-mobile-top .nav-expander {
      float: right;
      padding: 3% 3% 0 0; }
      #header .mobile-section .header-mobile-top .nav-expander.nav-expanded > span:first-child {
        transform: rotate(-45deg) translate(-5px, 5px); }
      #header .mobile-section .header-mobile-top .nav-expander.nav-expanded > span:nth-child(2) {
        transform: scaleX(0); }
      #header .mobile-section .header-mobile-top .nav-expander.nav-expanded > span:nth-child(3) {
        transform: rotate(45deg) translate(-5px, -5px); }
      #header .mobile-section .header-mobile-top .nav-expander span {
        user-select: none;
        transition: transform 0.3s ease-in-out;
        background-color: #CC0000;
        width: 27px;
        height: 2px;
        display: block;
        margin: 5px 0; }
  #header .mobile-section .nav-back {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2.14rem;
    line-height: 1.2;
    color: inherit;
    z-index: 20;
    color: #CC0000;
    padding-left: 2rem;
    padding-top: 0.2rem;
    left: 0;
    right: 0;
    transition: margin 0.3s 0.2s;
    margin-top: -50px; }
    @media all and (-ms-high-contrast: none) {
      #header .mobile-section .nav-back {
        font-weight: bold; } }
    @media only screen and (max-width: 767px) {
      #header .mobile-section .nav-back {
        font-size: 1.5rem; } }
    #header .mobile-section .nav-back::before {
      content: '<';
      display: inline-block;
      padding-right: 0.5rem; }
    #header .mobile-section .nav-back.show-btn {
      visibility: visible;
      opacity: 1;
      margin-top: 0; }

#header ul.dgs-mega-menu {
  text-align: right;
  display: block;
  padding: 0; }
  #header ul.dgs-mega-menu > li:first-child {
    padding-left: 0; }
  #header ul.dgs-mega-menu > li:last-child {
    padding-right: 0; }
  #header ul.dgs-mega-menu * {
    text-align: left; }
  #header ul.dgs-mega-menu ul {
    font-size: 0; }
  #header ul.dgs-mega-menu li {
    margin-top: 0;
    line-height: normal;
    text-indent: 0;
    padding-left: 0; }
  #header ul.dgs-mega-menu a {
    color: #A9A9A9;
    font-size: 1rem; }
    #header ul.dgs-mega-menu a:hover {
      color: #D3D3D3; }
    #header ul.dgs-mega-menu a.active {
      color: #D3D3D3; }
      #header ul.dgs-mega-menu a.active::before {
        display: none; }
    #header ul.dgs-mega-menu a.current {
      color: #D3D3D3; }
      #header ul.dgs-mega-menu a.current::before {
        display: none; }
  #header ul.dgs-mega-menu > li > a.active:before {
    background-color: #D3D3D3; }
  #header ul.dgs-mega-menu > li > ul {
    background-color: #eee;
    padding: 1rem 0;
    text-align: center;
    box-shadow: 0 0.3rem 0.4rem -0.4rem rgba(0, 0, 0, 0.2); }
    #header ul.dgs-mega-menu > li > ul::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -100%;
      bottom: 0;
      right: 99%;
      background-color: #eee;
      box-shadow: 0 0.3rem 0.4rem -0.4rem rgba(0, 0, 0, 0.2); }
    #header ul.dgs-mega-menu > li > ul::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 99%;
      bottom: 0;
      right: -100%;
      background-color: #eee;
      box-shadow: 0 0.3rem 0.4rem -0.4rem rgba(0, 0, 0, 0.2); }
    #header ul.dgs-mega-menu > li > ul > li {
      border-right: none;
      width: auto;
      padding: 0 1rem 0 0;
      display: inline-block;
      position: relative;
      vertical-align: top; }
    #header ul.dgs-mega-menu > li > ul a {
      padding: 0; }

#header .dgs-burger-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 10;
  padding: 0;
  background-color: #333; }
  #header .dgs-burger-menu li {
    padding: 1rem 2rem;
    margin-top: 0;
    line-height: normal;
    text-indent: 0;
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2.14rem;
    line-height: 1.2;
    color: inherit;
    border-bottom: thin solid #333;
    background-color: #CC0000; }
    @media all and (-ms-high-contrast: none) {
      #header .dgs-burger-menu li {
        font-weight: bold; } }
    @media only screen and (max-width: 767px) {
      #header .dgs-burger-menu li {
        font-size: 1.5rem; } }
    #header .dgs-burger-menu li:nth-child(even) {
      background-color: rgba(204, 0, 0, 0.7); }
    #header .dgs-burger-menu li:not(.no-dropdown):not(.sub-burger-title):not(.last-navigation) > a::after {
      content: '>';
      display: inline-block;
      float: right; }
    #header .dgs-burger-menu li.sub-burger-title {
      text-align: center;
      font-size: 2.2rem; }
    #header .dgs-burger-menu li.last-navigation, #header .dgs-burger-menu li.last-navigation ul, #header .dgs-burger-menu li.last-navigation li {
      background-color: #333; }
    #header .dgs-burger-menu li.last-navigation > a {
      padding-bottom: 1rem;
      border-bottom: 1px #D3D3D3 solid; }
    #header .dgs-burger-menu li.last-navigation ul {
      list-style: none;
      padding-top: 1rem; }
    #header .dgs-burger-menu li.last-navigation li {
      padding-left: 1rem; }
    #header .dgs-burger-menu li > a {
      width: 100%;
      color: #A9A9A9; }
    #header .dgs-burger-menu li ul {
      padding: 3rem 0 0 0;
      top: 0;
      bottom: 0;
      background-color: #333; }
  #header .dgs-burger-menu a {
    padding: 0;
    margin: 0; }

#wrapper #header #topHeader {
  height: 2.5rem;
  color: #fff;
  top: 0;
  background: #993231;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1000;
  display: block !important; }
  #wrapper #header #topHeader #topHeaderInner {
    max-width: 90%;
    width: 95rem;
    margin: 0.4rem auto; }
    #wrapper #header #topHeader #topHeaderInner a {
      color: #fff;
      font-weight: 400; }
    #wrapper #header #topHeader #topHeaderInner .component.link:first-child {
      float: left;
      margin-right: 2rem; }
    #wrapper #header #topHeader #topHeaderInner .component.link:nth-child(2) {
      float: left; }
    #wrapper #header #topHeader #topHeaderInner .component.link:nth-child(3) {
      float: right; }
      @media only screen and (max-width: 767px) {
        #wrapper #header #topHeader #topHeaderInner .component.link:nth-child(3) {
          display: none; } }

#wrapper #header .desktop-section {
  top: 2.5rem; }
  #wrapper #header .desktop-section::after {
    display: none; }
  #wrapper #header .desktop-section .inner-container {
    background: rgba(255, 255, 255, 0.9); }
    #wrapper #header .desktop-section .inner-container .header-desktop-top .inner-container {
      background: none; }
      #wrapper #header .desktop-section .inner-container .header-desktop-top .inner-container .row-1 .component.image img {
        margin: 0.9rem 0 0;
        padding: 0 0 0.9rem;
        width: 11rem; }

#wrapper #header .mobile-section .header-mobile-top {
  background: #fff;
  margin-top: 2.5rem; }
  #wrapper #header .mobile-section .header-mobile-top .image {
    width: 200px;
    padding: 1rem;
    float: left; }
  #wrapper #header .mobile-section .header-mobile-top .nav-expander {
    padding: 1.5rem 1rem 0 0; }
  #wrapper #header .mobile-section .header-mobile-top span {
    background-color: #993231; }

#wrapper #header .nav-back {
  text-align: right;
  margin-top: -1.25rem;
  margin-right: 6.5rem;
  z-index: 999;
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.14rem;
  line-height: 1.3;
  color: inherit; }
  @media all and (-ms-high-contrast: none) {
    #wrapper #header .nav-back {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    #wrapper #header .nav-back {
      font-size: 1rem; } }

#header .desktop-section .header-desktop-top > .inner-container {
  max-width: 90%; }

#header ul.dgs-mega-menu {
  text-align: left;
  display: block;
  padding: 0; }
  #header ul.dgs-mega-menu ul {
    font-size: 0;
    border-top: 3px solid #dd3333;
    max-width: 15rem;
    margin-top: .65rem; }
  #header ul.dgs-mega-menu a {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    width: 100%; }
    #header ul.dgs-mega-menu a:hover {
      color: #2ea3f2; }
  #header ul.dgs-mega-menu > li > ul {
    background-color: white;
    box-shadow: 0 2px 50px rgba(0, 0, 0, 0.15);
    text-align: left; }
    #header ul.dgs-mega-menu > li > ul::before {
      content: '';
      display: block;
      position: absolute;
      top: -3px;
      left: -10%;
      bottom: 0;
      right: 100%;
      background-color: white;
      border-top: 3px solid #dd3333; }
    #header ul.dgs-mega-menu > li > ul::after {
      content: '';
      display: block;
      position: absolute;
      top: -3px;
      left: 100%;
      bottom: 0;
      right: -10%;
      background-color: white;
      border-top: 3px solid #dd3333; }
    #header ul.dgs-mega-menu > li > ul > li {
      width: 100%;
      padding: .45rem 2rem;
      display: inline-block;
      position: relative;
      vertical-align: top;
      margin: 2px 0; }
      #header ul.dgs-mega-menu > li > ul > li:hover {
        background-color: #f8f8f8;
        border-radius: 4px; }

#header .dgs-burger-menu {
  top: 7.5rem !important;
  background-color: transparent;
  overflow-y: auto; }
  #header .dgs-burger-menu li {
    background-color: #f8f8f8;
    border-bottom: thin solid #eee;
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.14rem;
    line-height: 1.3;
    color: inherit; }
    @media all and (-ms-high-contrast: none) {
      #header .dgs-burger-menu li {
        font-weight: bold; } }
    @media only screen and (max-width: 767px) {
      #header .dgs-burger-menu li {
        font-size: 1rem; } }
    #header .dgs-burger-menu li:nth-child(even) {
      background-color: #fefefe; }
    #header .dgs-burger-menu li ul {
      background-color: #fff;
      padding: 0; }
    #header .dgs-burger-menu li.sub-burger-title {
      text-align: left;
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 2.14rem;
      line-height: 1.2;
      color: inherit; }
      @media all and (-ms-high-contrast: none) {
        #header .dgs-burger-menu li.sub-burger-title {
          font-weight: bold; } }
      @media only screen and (max-width: 767px) {
        #header .dgs-burger-menu li.sub-burger-title {
          font-size: 1.5rem; } }
    #header .dgs-burger-menu li > a {
      color: rgba(0, 0, 0, 0.6); }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.footer.check-mark-list ul {
  list-style: none;
  padding-left: 1rem; }
  .component.footer.check-mark-list ul li i {
    padding-right: 1rem;
    color: #993231; }

#footer .component.rich-text-list {
  background: #222;
  color: #dbdbdb;
  padding: 6rem 0; }
  #footer .component.rich-text-list ul li a {
    color: #dbdbdb; }
  #footer .component.rich-text-list ul li ul li {
    text-indent: 0;
    position: relative;
    border-bottom: none;
    padding-bottom: 0; }
    #footer .component.rich-text-list ul li ul li:not(:first-child) {
      margin-top: 0.2rem; }
    #footer .component.rich-text-list ul li ul li:before {
      border-radius: 3px;
      border-style: solid;
      border-width: 3px;
      content: "";
      border-color: #dd3333;
      position: absolute;
      top: 9px;
      left: 0; }
    #footer .component.rich-text-list ul li ul li a {
      font-weight: 400; }
  #footer .component.rich-text-list h1, #footer .component.rich-text-list h2, #footer .component.rich-text-list h3, #footer .component.rich-text-list h4 {
    color: #dd3333; }

#footer .component.rich-text {
  background: black;
  padding: 3rem 0;
  color: #666; }
  @media only screen and (min-width: 992px) {
    #footer .component.rich-text {
      padding: 1rem 0; } }
  #footer .component.rich-text .component-content {
    text-align: center; }
    @media only screen and (min-width: 992px) {
      #footer .component.rich-text .component-content {
        text-align: left; } }
    #footer .component.rich-text .component-content p {
      padding-top: 20px; }
      @media only screen and (min-width: 992px) {
        #footer .component.rich-text .component-content p {
          padding-top: 0px; } }
    #footer .component.rich-text .component-content .icomoon {
      float: none;
      font-size: 2.2rem;
      margin-top: 0px;
      padding: 8px;
      text-align: center; }
      @media only screen and (min-width: 992px) {
        #footer .component.rich-text .component-content .icomoon {
          float: right;
          margin-top: -.9rem; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
/* Tables
================================== */
/*
- make mobile switch sticky
*/
.comparison-table article {
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  position: relative; }

.comparison-table ul {
  top: 0px;
  z-index: 10;
  padding-bottom: 1rem; }
  .comparison-table ul li {
    list-style: none;
    flex: 1;
    margin: 0;
    background: #fff;
    padding: 1rem;
    border: 1px solid #666; }
    .comparison-table ul li a {
      text-decoration: none;
      color: #222;
      text-indent: 0;
      display: block; }
  .comparison-table ul li.active {
    background: #993231; }
    .comparison-table ul li.active a {
      color: #fff; }

.comparison-table table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%; }

.comparison-table th {
  background: #eee;
  display: none;
  position: sticky;
  top: 92px;
  vertical-align: middle; }

.comparison-table td, .comparison-table th {
  height: 3rem;
  vertical-align: middle; }

.comparison-table td, .comparison-table th {
  border: 1px solid #666;
  padding: .25rem .65rem;
  empty-cells: show; }

.comparison-table td, .comparison-table th {
  text-align: left; }

.comparison-table td + td, .comparison-table th + th {
  text-align: center;
  display: none; }

.comparison-table td.default {
  display: table-cell; }

.comparison-table .sep {
  background: #fff;
  font-weight: bold; }

.comparison-table .tick {
  font-size: 1.2rem;
  color: #993231; }

.comparison-table .hide {
  border: 0;
  background: none; }

@media (min-width: 780px) {
  .comparison-table ul {
    display: none; }
  .comparison-table td, .comparison-table th {
    display: table-cell !important; }
  .comparison-table td + td, .comparison-table th + th {
    width: auto; } }

.extranet-login #page-wrap, .extranet-forgot-password #page-wrap, .extranet-request-account #page-wrap, .extranet-complete-account #page-wrap, .extranet-update-account #page-wrap, .extranet-set-password #page-wrap {
  max-width: 100%;
  width: 72rem;
  margin: 6rem auto 2rem; }
  .extranet-login #page-wrap table, .extranet-forgot-password #page-wrap table, .extranet-request-account #page-wrap table, .extranet-complete-account #page-wrap table, .extranet-update-account #page-wrap table, .extranet-set-password #page-wrap table {
    width: 100%; }
    .extranet-login #page-wrap table td, .extranet-forgot-password #page-wrap table td, .extranet-request-account #page-wrap table td, .extranet-complete-account #page-wrap table td, .extranet-update-account #page-wrap table td, .extranet-set-password #page-wrap table td {
      padding: 2rem; }
      .extranet-login #page-wrap table td:first-child, .extranet-forgot-password #page-wrap table td:first-child, .extranet-request-account #page-wrap table td:first-child, .extranet-complete-account #page-wrap table td:first-child, .extranet-update-account #page-wrap table td:first-child, .extranet-set-password #page-wrap table td:first-child {
        background: #f8f8f8;
        border-radius: 5px; }
    .extranet-login #page-wrap table input, .extranet-forgot-password #page-wrap table input, .extranet-request-account #page-wrap table input, .extranet-complete-account #page-wrap table input, .extranet-update-account #page-wrap table input, .extranet-set-password #page-wrap table input {
      border: 1px solid #222 !important;
      color: #222 !important;
      padding: 12px 10px !important;
      background: #fefefe !important; }
    .extranet-login #page-wrap table input[type="submit"], .extranet-forgot-password #page-wrap table input[type="submit"], .extranet-request-account #page-wrap table input[type="submit"], .extranet-complete-account #page-wrap table input[type="submit"], .extranet-update-account #page-wrap table input[type="submit"], .extranet-set-password #page-wrap table input[type="submit"] {
      color: #fff !important;
      border-radius: 100px;
      letter-spacing: 1px;
      font-size: 16px;
      font-weight: 700 !important;
      text-transform: uppercase !important;
      padding-left: 2em;
      padding-right: 2em;
      padding-top: 1em;
      padding-bottom: 1em;
      margin-top: 2rem;
      background-color: #dd3333 !important;
      transition: 0.2s ease;
      border: 1px solid #dd3333 !important; }
      .extranet-login #page-wrap table input[type="submit"]:hover, .extranet-forgot-password #page-wrap table input[type="submit"]:hover, .extranet-request-account #page-wrap table input[type="submit"]:hover, .extranet-complete-account #page-wrap table input[type="submit"]:hover, .extranet-update-account #page-wrap table input[type="submit"]:hover, .extranet-set-password #page-wrap table input[type="submit"]:hover {
        background-color: #993231; }
    .extranet-login #page-wrap table .forgot-password, .extranet-forgot-password #page-wrap table .forgot-password, .extranet-request-account #page-wrap table .forgot-password, .extranet-complete-account #page-wrap table .forgot-password, .extranet-update-account #page-wrap table .forgot-password, .extranet-set-password #page-wrap table .forgot-password {
      margin-top: 1rem; }

.component.accordion-spot {
  margin-top: 5rem; }
  .component.accordion-spot .component-content {
    position: relative; }
    .component.accordion-spot .component-content .accordion-spot-elements__expand-all,
    .component.accordion-spot .component-content .accordion-spot-element__title {
      cursor: pointer; }
    .component.accordion-spot .component-content .accordion-spot-elements__expand-all {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1; }
  .component.accordion-spot .accordion-spot-element__content {
    display: none; }
  .component.accordion-spot .accordion-spot-element.expand .accordion-spot-element__content {
    display: inherit; }
  .component.accordion-spot .accordion-spot-element.shrink {
    cursor: pointer; }

.component.accordion-spot.tabs .accordion-spot-tabs {
  display: flex;
  overflow-x: auto; }

.component.accordion-spot.tabs .accordion-spot-tab {
  cursor: pointer; }

.component.accordion-spot.tabs .accordion-spot-element + .accordion-spot-element {
  margin-top: 0; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
/* REGULAR STYLING */
.component.accordion-spot .component-content {
  position: relative;
  width: 95rem;
  max-width: 80%;
  margin: 0 auto;
  padding: 4rem 0; }
  .component.accordion-spot .component-content .accordion-spot-elements__expand-all {
    display: none; }
    .component.accordion-spot .component-content .accordion-spot-elements__expand-all::after {
      display: none; }
    .component.accordion-spot .component-content .accordion-spot-elements__expand-all.shrink-all::after {
      display: none; }
  .component.accordion-spot .component-content .accordion-spot-element__title {
    font-size: 1.2rem;
    background: #dd3333;
    color: #fefefe;
    position: relative;
    padding: 1rem 1.4rem; }
    .component.accordion-spot .component-content .accordion-spot-element__title::before {
      position: absolute;
      top: 40%;
      right: 1.4rem;
      content: "";
      background: url(/design/build/medrx/assets/arrow-clean.svg) no-repeat;
      background-size: contain;
      background-position: center;
      width: 0.8em;
      height: 0.8em;
      display: block;
      transform: rotate(90deg);
      transform-origin: 50% 50%; }
  .component.accordion-spot .component-content .accordion-spot-element.expand .accordion-spot-element__title::before {
    transform: rotate(-90deg); }
  .component.accordion-spot .component-content .accordion-spot-element__content {
    margin-top: 1rem;
    border: 1px solid #eee;
    padding: 1.4rem; }
  .component.accordion-spot .component-content .accordion-spot-element__body img {
    max-width: 100%; }
  .component.accordion-spot .component-content .accordion-spot-element__body ul, .component.accordion-spot .component-content .accordion-spot-element__body ol {
    margin: 1rem;
    position: relative; }
    .component.accordion-spot .component-content .accordion-spot-element__body ul li, .component.accordion-spot .component-content .accordion-spot-element__body ol li {
      height: 1.5em; }
  .component.accordion-spot .component-content .accordion-spot-element + .accordion-spot-element {
    margin-top: 24px; }

/* GENERAL TAB STYLING */
.component.accordion-spot.tabs .accordion-spot-element__title {
  padding-left: 0; }
  .component.accordion-spot.tabs .accordion-spot-element__title::before {
    display: none;
    background: none; }

.component.accordion-spot.tabs .accordion-spot-element__content {
  padding: 41px 0 0 0; }

/* BUTTON TAB STYLING */
.component.accordion-spot.tabs.button .accordion-spot-tab {
  margin-right: 10px; }
  .component.accordion-spot.tabs.button .accordion-spot-tab__title {
    border: 1px solid #666;
    background-color: transparent;
    color: #666; }
  .component.accordion-spot.tabs.button .accordion-spot-tab.passive:hover .accordion-spot-tab__title, .component.accordion-spot.tabs.button .accordion-spot-tab.active .accordion-spot-tab__title {
    border: 1px solid #993231;
    background-color: #993231;
    color: #fff; }

/* BUTTON TAB STYLING */
/* HEADLINE TAB STYLING */
.component.accordion-spot.tabs.headline .accordion-spot-tab {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  min-width: 100px;
  border-bottom: 3px solid #993231;
  padding-bottom: 11px; }
  .component.accordion-spot.tabs.headline .accordion-spot-tab.passive {
    border-bottom-color: #666; }
  .component.accordion-spot.tabs.headline .accordion-spot-tab__title {
    border: 0;
    padding-bottom: 4px; }

#wrapper .component.blog .blog-image img {
  width: 100%;
  display: block; }

#wrapper .component.blog .component-content {
  width: 60rem;
  max-width: 90%;
  margin: 0 auto;
  padding-top: 2rem; }
  #wrapper .component.blog .component-content .blog-reading-time,
  #wrapper .component.blog .component-content .blog-author {
    line-height: 1.5; }
  #wrapper .component.blog .component-content .blog-date {
    line-height: 1.5; }
  #wrapper .component.blog .component-content .blog-author-value {
    color: inherit;
    font-family: inherit; }
  #wrapper .component.blog .component-content .blog-title {
    margin: 1rem 0 0.2rem; }
  #wrapper .component.blog .component-content .blog-introduction {
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: inherit;
    line-height: 1.6;
    font-weight: 700; }
  #wrapper .component.blog .component-content .blog-content {
    margin-bottom: 0.8rem; }

@media only screen and (min-width: 992px) {
  #wrapper .component.blog .component-content .blog-title {
    margin: 2rem 0; } }

.component.blog-list #blog-list li,
.component.latest-blog-posts #blog-list li {
  list-style: none;
  text-indent: 0;
  padding: 0;
  margin: 1rem; }
  .component.blog-list #blog-list li > a,
  .component.latest-blog-posts #blog-list li > a {
    display: block; }
  .component.blog-list #blog-list li .news-title,
  .component.latest-blog-posts #blog-list li .news-title {
    display: none; }
  .component.blog-list #blog-list li .news-text,
  .component.blog-list #blog-list li .news-link,
  .component.latest-blog-posts #blog-list li .news-text,
  .component.latest-blog-posts #blog-list li .news-link {
    margin-top: 1rem;
    text-align: center; }
  .component.blog-list #blog-list li .news-image,
  .component.latest-blog-posts #blog-list li .news-image {
    text-align: center; }
    .component.blog-list #blog-list li .news-image img,
    .component.latest-blog-posts #blog-list li .news-image img {
      max-width: 100%; }
  .component.blog-list #blog-list li .news-text,
  .component.latest-blog-posts #blog-list li .news-text {
    line-height: 1.5rem; }
  .component.blog-list #blog-list li .news-link span,
  .component.latest-blog-posts #blog-list li .news-link span {
    margin: 0; }
  .component.blog-list #blog-list li .news-date,
  .component.latest-blog-posts #blog-list li .news-date {
    text-align: center;
    padding: 0.5rem 0;
    color: #333; }

@media only screen and (min-width: 768px) {
  .component.blog-list #blog-list,
  .component.latest-blog-posts #blog-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 3.6rem 0; }
    .component.blog-list #blog-list li,
    .component.latest-blog-posts #blog-list li {
      box-sizing: border-box;
      margin: 0 auto 1rem auto;
      padding: 0 2%;
      width: 33%; }
      .component.blog-list #blog-list li .news-image img,
      .component.latest-blog-posts #blog-list li .news-image img {
        max-height: 280px; }
      .component.blog-list #blog-list li .news-text,
      .component.latest-blog-posts #blog-list li .news-text {
        height: 6rem;
        max-height: 6rem;
        overflow: hidden; } }

@media only screen and (min-width: 992px) {
  .component.blog-list .component-content,
  .component.latest-blog-posts .component-content {
    position: relative;
    width: 95rem;
    max-width: 90%;
    margin: 0 auto; } }

#wrapper .component.blog {
  font-size: 16px; }
  #wrapper .component.blog > .component-content {
    padding-top: 1rem; }
  #wrapper .component.blog .blog-image {
    margin-top: 1rem; }
    #wrapper .component.blog .blog-image .blog-introduction {
      line-height: 1;
      font-size: 1.3rem; }
    #wrapper .component.blog .blog-image h1,
    #wrapper .component.blog .blog-image h2,
    #wrapper .component.blog .blog-image .like-h1,
    #wrapper .component.blog .blog-image .like-h2 {
      font-weight: bold;
      margin-bottom: 1rem; }
    #wrapper .component.blog .blog-image .blog-headline {
      padding-bottom: 1rem;
      position: relative;
      width: 95rem;
      max-width: 90%;
      margin: 0 auto; }
      #wrapper .component.blog .blog-image .blog-headline .blog-title {
        margin: 3rem 0 1.2rem;
        font-weight: normal;
        color: #000; }
      #wrapper .component.blog .blog-image .blog-headline .blog-author,
      #wrapper .component.blog .blog-image .blog-headline .blog-date,
      #wrapper .component.blog .blog-image .blog-headline .blog-reading-time {
        font-size: 0.9rem;
        margin: 0.2rem 0.1rem;
        color: #666; }
      #wrapper .component.blog .blog-image .blog-headline .blog-date,
      #wrapper .component.blog .blog-image .blog-headline .blog-reading-time {
        display: inline-block; }
      #wrapper .component.blog .blog-image .blog-headline .blog-author {
        text-transform: capitalize; }
      #wrapper .component.blog .blog-image .blog-headline .blog-author-value {
        color: #000; }
      #wrapper .component.blog .blog-image .blog-headline .blog-date {
        text-transform: capitalize; }
        #wrapper .component.blog .blog-image .blog-headline .blog-date::after {
          content: "-";
          margin: 0 0.2rem; }
  #wrapper .component.blog .blog-content {
    position: relative;
    width: 95rem;
    max-width: 90%;
    margin: 0 auto;
    font-size: 0.9rem; }
    #wrapper .component.blog .blog-content h1,
    #wrapper .component.blog .blog-content h2,
    #wrapper .component.blog .blog-content h3,
    #wrapper .component.blog .blog-content h4,
    #wrapper .component.blog .blog-content h5,
    #wrapper .component.blog .blog-content h6 {
      margin-top: 2.5rem;
      margin-bottom: 0.6rem; }
    #wrapper .component.blog .blog-content img {
      max-width: 100% !important; }
    @media only screen and (max-width: 767px) {
      #wrapper .component.blog .blog-content h3,
      #wrapper .component.blog .blog-content h4,
      #wrapper .component.blog .blog-content h5,
      #wrapper .component.blog .blog-content h6,
      #wrapper .component.blog .blog-content .like-h3,
      #wrapper .component.blog .blog-content .like-h4,
      #wrapper .component.blog .blog-content .like-h5 {
        font-size: 1.6rem; }
      #wrapper .component.blog .blog-content .blog-introduction {
        display: none; }
      #wrapper .component.blog .blog-content img {
        height: auto !important; }
      #wrapper .component.blog .blog-content span.link:before {
        top: calc(50% - 0.5em);
        line-height: 1.3; } }
    #wrapper .component.blog .blog-content strong {
      font-weight: 400; }
    #wrapper .component.blog .blog-content ul {
      list-style: disc;
      line-height: 1.5;
      padding-left: 2rem;
      margin-bottom: 1rem; }
      @media only screen and (max-width: 767px) {
        #wrapper .component.blog .blog-content ul li {
          font-size: 1rem; } }
    #wrapper .component.blog .blog-content ol {
      list-style: none;
      counter-reset: item;
      line-height: 1.7;
      padding-left: 2rem; }
      #wrapper .component.blog .blog-content ol li {
        counter-increment: item;
        margin-bottom: 0.28rem;
        font-size: 1rem; }
        @media only screen and (max-width: 767px) {
          #wrapper .component.blog .blog-content ol li {
            font-size: 1.2rem; } }
        #wrapper .component.blog .blog-content ol li:before {
          content: counter(item) ". ";
          font-weight: bold;
          display: inline-block;
          margin-right: 0.28rem; }
  #wrapper .component.blog .blog-introduction .bigger,
  #wrapper .component.blog .blog-introduction strong {
    font-size: 1rem;
    color: #000;
    font-weight: normal; }
    @media only screen and (max-width: 767px) {
      #wrapper .component.blog .blog-introduction .bigger,
      #wrapper .component.blog .blog-introduction strong {
        line-height: 1.4;
        font-size: 1.4rem; } }
  @media only screen and (min-width: 768px) {
    #wrapper .component.blog {
      font-size: inherit; }
      #wrapper .component.blog .blog-image {
        position: relative;
        overflow: hidden; }
        #wrapper .component.blog .blog-image .blog-introduction {
          display: none; }
        #wrapper .component.blog .blog-image .blog-headline {
          position: absolute;
          width: 60%;
          margin: 0;
          background-color: rgba(153, 50, 49, 0.9);
          bottom: 0;
          padding: 1.5rem 3rem; }
          #wrapper .component.blog .blog-image .blog-headline .blog-author,
          #wrapper .component.blog .blog-image .blog-headline .blog-date,
          #wrapper .component.blog .blog-image .blog-headline .blog-reading-time {
            color: #fefefe; }
          #wrapper .component.blog .blog-image .blog-headline .blog-title {
            color: #fefefe;
            font-size: 1.8rem;
            margin: 0 0 0.6rem; } }
        @media only screen and (min-width: 768px) and (max-width: 767px) {
          #wrapper .component.blog .blog-image .blog-headline .blog-title {
            color: #000;
            font-size: 2.2rem;
            margin: 1rem 0; } }
  @media only screen and (min-width: 768px) {
          #wrapper .component.blog .blog-image .blog-headline .blog-author .blog-author-value {
            color: #fefefe;
            transition: color 0.2s; }
          #wrapper .component.blog .blog-image .blog-headline .blog-author a.blog-author-value:hover {
            color: #993231; }
          #wrapper .component.blog .blog-image .blog-headline .blog-date::after {
            content: "•";
            margin: 0 0.2rem;
            vertical-align: middle; } }
  @media only screen and (min-width: 992px) {
    #wrapper .component.blog .blog-content {
      width: auto;
      max-width: 100%; }
    #wrapper .component.blog .blog-image .blog-headline {
      bottom: 2rem; } }

.component.author-details .component-content {
  max-width: 550px;
  margin: 3rem auto;
  width: 90%; }
  .component.author-details .component-content .author-image {
    text-align: center;
    margin-bottom: 2rem; }
    .component.author-details .component-content .author-image img {
      max-width: 80%; }

.component.blog-categories {
  position: relative;
  -ms-overflow-style: none;
  border-bottom: 1px solid #993231;
  padding: 0; }
  .component.blog-categories .blog-categories-content {
    max-width: 100vw;
    width: 100%;
    position: relative; }
    .component.blog-categories .blog-categories-content::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: calc(100% - 1px);
      width: 20%;
      max-width: 3rem;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 40%, white 60%); }
    .component.blog-categories .blog-categories-content-wrapper {
      display: flex;
      align-items: center;
      overflow-y: scroll;
      text-align: center;
      height: 3rem; }
      .component.blog-categories .blog-categories-content-wrapper::-webkit-scrollbar {
        max-height: 0;
        max-width: 0; }
      .component.blog-categories .blog-categories-content-wrapper::-webkit-scrollbar-thumb {
        height: 0; }
      .component.blog-categories .blog-categories-content-wrapper::-webkit-scrollbar-track {
        height: 0; }
  .component.blog-categories .blog-category:last-of-type {
    padding-right: 25px; }
  .component.blog-categories .blog-category a {
    padding: 5px 1rem;
    font-size: 0.8rem;
    color: #666;
    display: block;
    text-transform: uppercase;
    position: relative; }
    .component.blog-categories .blog-category a::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -0.28rem;
      width: 0;
      margin: 0 auto;
      border-bottom: 2px solid #666;
      transition: width 0.2s ease-out; }
    .component.blog-categories .blog-category a.active::after, .component.blog-categories .blog-category a:hover::after {
      width: 1.62rem; }
  @media only screen and (min-width: 768px) {
    .component.blog-categories {
      position: relative;
      width: 95rem;
      max-width: 90%;
      margin: 0 auto;
      -ms-overflow-style: none; }
      .component.blog-categories .blog-categories-content-wrapper .blog-category {
        margin: 0.8rem 1rem;
        text-align: center; }
        .component.blog-categories .blog-categories-content-wrapper .blog-category:first-of-type {
          margin-left: 0; }
        .component.blog-categories .blog-categories-content-wrapper .blog-category a {
          padding: 0.5rem 0;
          display: inline-block;
          font-size: 0.8rem;
          position: relative; } }

.blog--two-col .component.blog-categories {
  max-width: 100%; }

#wrapper .intro-banner.blog-banner h1 {
  font-size: 1.8rem; }

#wrapper .intro-banner.blog-banner .intro-banner-content {
  position: absolute !important;
  display: flex; }
  #wrapper .intro-banner.blog-banner .intro-banner-content .outer-pos-wrapper {
    display: flex;
    align-items: center; }

@media only screen and (min-width: 768px) {
  #wrapper .intro-banner.blog-banner {
    display: block; }
    #wrapper .intro-banner.blog-banner h1 {
      font-size: 2.6rem; } }

.component.blog-list {
  font-size: 18px; }
  .component.blog-list #blog-list li > a {
    background-color: #eee;
    height: 100%;
    position: relative;
    display: inline-block;
    width: 100%; }
    .component.blog-list #blog-list li > a .news-date {
      text-align: left;
      font-size: 0.8rem;
      color: #666;
      padding: 1.5rem 0 0.8rem 0; }
    .component.blog-list #blog-list li > a .news-title {
      display: block;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      font-size: 1.1rem;
      font-weight: 400; }
      .component.blog-list #blog-list li > a .news-title span {
        margin: 0;
        padding: 0;
        color: #000;
        font-size: 1.6rem;
        line-height: 1.3; }
    .component.blog-list #blog-list li > a .news-text {
      margin-top: 0.5rem;
      margin-bottom: 3rem;
      color: #666;
      font-size: 1rem;
      line-height: 1.5em; }
    .component.blog-list #blog-list li > a .news-link {
      position: absolute !important;
      bottom: 0.8rem;
      left: 1rem;
      font-size: 1rem !important; }
    .component.blog-list #blog-list li > a .news-date,
    .component.blog-list #blog-list li > a .news-title,
    .component.blog-list #blog-list li > a .news-text {
      margin-left: 1rem;
      margin-right: 1rem;
      text-align: left; }
  @media only screen and (min-width: 768px) {
    .component.blog-list > .bloglist {
      position: relative;
      width: 95rem;
      max-width: 90%;
      margin: 0 auto; }
    .component.blog-list #blog-list {
      margin: 0 -1%;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: stretch; }
      .component.blog-list #blog-list > li {
        width: 48%;
        padding: 0;
        margin: 1%; }
        .component.blog-list #blog-list > li > a .news-text {
          margin-bottom: 2.5rem; } }
  @media only screen and (min-width: 992px) {
    .component.blog-list #blog-list > li {
      width: 31.3%; }
      .component.blog-list #blog-list > li > a .news-image img {
        max-height: 200px; } }
  @media only screen and (min-width: 1200px) {
    .component.blog-list #blog-list > li {
      width: 23%; }
      .component.blog-list #blog-list > li > a .news-image img {
        max-height: 200px; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
#wrapper .component.related-blogs {
  font-size: 0.9rem;
  position: relative;
  width: 95rem;
  max-width: 90%;
  margin: 0 auto; }
  #wrapper .component.related-blogs .related-title {
    font-size: 1rem;
    margin-bottom: 0.9rem;
    font-family: serif;
    text-align: center; }
  #wrapper .component.related-blogs ul > li {
    box-sizing: border-box;
    margin: 0 0 2.5rem 0; }
    #wrapper .component.related-blogs ul > li:last-child {
      margin-bottom: 0; }
    #wrapper .component.related-blogs ul > li .news-image img {
      max-height: 200px; }
    #wrapper .component.related-blogs ul > li .news-text {
      overflow: hidden;
      margin-bottom: 3rem; }
    #wrapper .component.related-blogs ul > li > div {
      background: #A9A9A9;
      border: 1px solid #A9A9A9;
      position: relative; }
      #wrapper .component.related-blogs ul > li > div:hover {
        cursor: pointer; }
      #wrapper .component.related-blogs ul > li > div .news-image {
        text-align: center;
        margin: 0 -1px; }
      #wrapper .component.related-blogs ul > li > div .news-date {
        text-align: left;
        padding: 1.5rem 0 0;
        font-size: 0.8rem;
        color: #666; }
      #wrapper .component.related-blogs ul > li > div .news-title {
        display: block;
        margin-top: 1.2rem;
        margin-bottom: 0.5rem;
        padding: 0;
        color: #000;
        font-size: 1.6rem; }
      #wrapper .component.related-blogs ul > li > div .news-text {
        color: #666;
        font-size: 1rem;
        line-height: 1.5rem;
        margin-top: 0.5rem;
        margin-bottom: 4rem; }
      #wrapper .component.related-blogs ul > li > div .news-link {
        position: absolute;
        bottom: 0;
        margin-bottom: 0.4rem;
        font-size: 1rem; }
        #wrapper .component.related-blogs ul > li > div .news-link::before {
          font-size: 1rem; }
      #wrapper .component.related-blogs ul > li > div .news-date,
      #wrapper .component.related-blogs ul > li > div .news-title,
      #wrapper .component.related-blogs ul > li > div .news-text,
      #wrapper .component.related-blogs ul > li > div .news-link {
        margin-left: 1rem;
        margin-right: 1rem;
        text-align: left; }
  @media only screen and (min-width: 768px) {
    #wrapper .component.related-blogs {
      display: block;
      margin: 0;
      max-width: 100%; }
      #wrapper .component.related-blogs ul li {
        margin-bottom: 4rem; } }
  @media only screen and (min-width: 992px) {
    #wrapper .component.related-blogs {
      font-size: 1rem; } }

@media only screen and (min-width: 768px) {
  #wrapper .component.latest-blog-posts {
    width: 500px;
    max-width: 90%; } }

@media only screen and (max-width: 767px) {
  #wrapper .component.latest-blog-posts .news-text,
  #wrapper .component.latest-blog-posts .news-link {
    display: none; }
  #wrapper .component.latest-blog-posts .news-image .news-date {
    padding-top: 1rem;
    padding-bottom: 0.6rem;
    color: #666;
    font-size: 0.9rem; } }

#wrapper .component.latest-blog-posts .latest-blog-headline {
  font-size: 1.4rem;
  margin-bottom: 0.9rem;
  text-transform: uppercase;
  text-align: center; }

#wrapper .component.latest-blog-posts #blog-list {
  margin: 0; }

#wrapper .component.latest-blog-posts ul > li {
  box-sizing: border-box;
  margin: 0 0 2.5rem 0;
  width: 100%;
  padding: 0;
  background-color: #eee;
  position: relative; }
  #wrapper .component.latest-blog-posts ul > li:last-child {
    margin-bottom: 0; }
  #wrapper .component.latest-blog-posts ul > li .news-text {
    overflow: hidden; }
  #wrapper .component.latest-blog-posts ul > li:hover {
    cursor: pointer; }
  #wrapper .component.latest-blog-posts ul > li .news-image {
    text-align: center;
    margin: 0; }
    #wrapper .component.latest-blog-posts ul > li .news-image img {
      max-height: 100%; }
  #wrapper .component.latest-blog-posts ul > li .news-date {
    text-align: left;
    padding: 1.5rem 0 0;
    font-size: 0.8rem;
    color: #666; }
    @media only screen and (max-width: 767px) {
      #wrapper .component.latest-blog-posts ul > li .news-date {
        font-size: 1rem; } }
  #wrapper .component.latest-blog-posts ul > li .news-title {
    display: block;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    padding: 0;
    color: #000;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.2; }
    @media only screen and (max-width: 767px) {
      #wrapper .component.latest-blog-posts ul > li .news-title {
        margin-top: 0.5rem;
        margin-bottom: 0;
        padding-bottom: 0.5rem;
        font-size: 1.4rem;
        line-height: 1.3; } }
  #wrapper .component.latest-blog-posts ul > li .news-text {
    color: #666;
    font-size: 1rem;
    line-height: 1.2rem;
    margin-top: 0.5rem;
    height: auto;
    max-height: none; }
  #wrapper .component.latest-blog-posts ul > li .news-link {
    margin: 1rem 0 0.6rem 1rem !important;
    font-size: 1rem !important; }
    #wrapper .component.latest-blog-posts ul > li .news-link::before {
      font-size: 1rem; }
  #wrapper .component.latest-blog-posts ul > li .news-date,
  #wrapper .component.latest-blog-posts ul > li .news-title,
  #wrapper .component.latest-blog-posts ul > li .news-text,
  #wrapper .component.latest-blog-posts ul > li .news-link {
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: left; }

@media only screen and (min-width: 768px) {
  #wrapper .component.latest-blog-posts {
    display: block;
    max-width: 100%; } }

@media only screen and (min-width: 992px) {
  #wrapper .component.latest-blog-posts {
    font-size: 1rem; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
#wrapper #content .component.two-section-grid.blog--two-col {
  margin: 2rem auto;
  max-width: 100%; }

@media only screen and (min-width: 768px) {
  #wrapper #content .component.two-section-grid.blog--two-col {
    max-width: 90%; }
  #wrapper #content .component.two-section-grid.blog--two-col > .component-content {
    display: flex;
    justify-content: space-between; }
    #wrapper #content .component.two-section-grid.blog--two-col > .component-content > .section-one {
      width: 66%; }
    #wrapper #content .component.two-section-grid.blog--two-col > .component-content > .section-two {
      width: 28%;
      margin: 0; } }

#wrapper #content .component.two-section-grid.two-section-grid-full-width {
  width: 100%;
  max-width: 100%; }

#wrapper #content .component.two-section-grid.formWithImg .component-content {
  display: flex;
  position: unset;
  width: 100%;
  max-width: 100%; }
  #wrapper #content .component.two-section-grid.formWithImg .component-content .section-one {
    width: 54%; }
    @media only screen and (max-width: 991px) {
      #wrapper #content .component.two-section-grid.formWithImg .component-content .section-one {
        width: 100%; } }
  #wrapper #content .component.two-section-grid.formWithImg .component-content .section-two {
    margin-left: 0 !important;
    flex-grow: 1; }
    @media only screen and (max-width: 991px) {
      #wrapper #content .component.two-section-grid.formWithImg .component-content .section-two {
        display: none; } }

.component.promotion {
  padding: 0; }
  .component.promotion .component-content {
    position: relative;
    width: 95rem;
    max-width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
    margin: 0 auto 2.5rem;
    padding: 0;
    background-color: #eee;
    position: relative;
    width: 500px;
    max-width: 90%; }
  .component.promotion .promotion-banner {
    background-color: #fff; }
    .component.promotion .promotion-banner img {
      width: 100%; }
    .component.promotion .promotion-banner::before {
      content: "PROMOTIONS";
      text-align: center;
      display: block;
      font-size: 1.4rem;
      padding-bottom: 2rem;
      margin-top: 1.5rem; }
  .component.promotion .promotion-header,
  .component.promotion .promotion-text,
  .component.promotion .promotion-action {
    margin: 1rem; }
  .component.promotion .promotion-header {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.2; }
  .component.promotion .promotion-action {
    display: inline-block;
    padding: 1rem 2rem;
    width: fit-content;
    background: #666;
    color: #fff;
    border-radius: 100px;
    border-width: 0 !important;
    font-size: 1rem;
    letter-spacing: 0.1em;
    font-weight: 600;
    text-shadow: none;
    transition: 0.3s ease;
    cursor: pointer;
    padding: 0.6rem 1.4rem;
    margin: 0.8rem; }
    @media only screen and (min-width: 992px) {
      .component.promotion .promotion-action {
        font-size: 1.2rem;
        font-weight: 600;
        padding: 1.1rem 2.2rem; } }
    .component.promotion .promotion-action:hover {
      background: #e0c588; }
    .component.promotion .promotion-action a {
      color: #fff; }
    @media only screen and (min-width: 992px) {
      .component.promotion .promotion-action {
        font-size: 1rem;
        padding: 0.8rem 1.6rem; } }

.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  cursor: pointer; }

.flexspot-mailchimp .component-content {
  position: relative;
  width: 95rem;
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px; }
  .flexspot-mailchimp .component-content a.button-primary {
    padding: 0.6rem 1.2rem;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 50px;
    margin-top: 2rem;
    display: inline-block;
    font-weight: 400;
    font-size: 1.5rem;
    transition: all 0.3s;
    cursor: pointer; }
    .flexspot-mailchimp .component-content a.button-primary:hover {
      background: rgba(255, 255, 255, 0.2);
      border: 2px solid transparent;
      padding: 0.6rem 1.4rem; }

.mailchimpDialog {
  background: #fff;
  padding: 15px;
  z-index: 9999;
  position: fixed;
  left: 50% !important;
  transform: translateX(-50%) !important;
  max-width: 100%; }
  .mailchimpDialog .mc-field-group {
    margin-bottom: 10px; }
    .mailchimpDialog .mc-field-group input {
      width: 100% !important; }
  .mailchimpDialog .indicates-required {
    margin: 5px 0 15px; }
  .mailchimpDialog .ui-dialog-titlebar-close {
    position: absolute;
    right: 15px;
    border: 0; }
  .mailchimpDialog #mc_embed_signup #mc-embedded-subscribe {
    margin-top: 15px;
    margin-left: auto;
    display: block; }
  .mailchimpDialog #mce-responses {
    text-align: center;
    margin: 15px auto; }
    .mailchimpDialog #mce-responses #mce-success-response {
      color: #1b671b; }
    .mailchimpDialog #mce-responses div#mce-error-response {
      color: #993231; }

/*# sourceMappingURL=main.[hash].css.map */